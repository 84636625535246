import React, { useState, useEffect } from 'react';
import styles from './Note.module.scss';
import className from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import NoteAPI from '~/Api/Note';

const css = className.bind(styles);

function Note() {
    const [isOpen, setIsOpen] = useState(false);
    const [listNote, setListNote] = useState([]);

    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const n = new NoteAPI();
        n.getListNote().then((response) => {
            if (listNote.length === 0) {
                setListNote(response.data);
            }
        });
    }, []);

    return (
        <div>
            {/* <button className={css('btn-note')} onClick={handleOpen}>
                <FontAwesomeIcon icon={faNoteSticky} /> Chú thích
            </button>
            {isOpen && (
                <div className={css('map-note')}>
                    <p className={css('note-title')}>CHÚ THÍCH</p>
                    <div className={css('group-note')}>
                        {listNote.map((res, idx) => (
                            <div className={css('item-note', idx % 2 === 0 ? '' : 'item-1')}>
                                <img className={css('img')} src={res.path} />
                                <p className={css('title')}>{res.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )} */}
        </div>
    );
}

export default Note;
