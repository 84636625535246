import { useContext } from 'react';
import NotificationContext from './NotificationContext';
import classNames from 'classnames/bind';
import styles from './Notification.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import CommonUtils from '~/Utils/CommonUtils';
import CommonConst from '~/Utils/CommonConst';

const css = classNames.bind(styles);

export default Notification = () => {
    const { showMessage, showNotification, type, message } = useContext(NotificationContext);

    const closeNotification = () => {
        showMessage({ showNotification: false });
    };

    const getNotificationClassName = (type) => {
        let className = 'toast';

        switch (type) {
            case CommonConst.MSG_TYPE_CONST.ERROR:
                className += '--error';
                break;

            case CommonConst.MSG_TYPE_CONST.INFO:
                className += '--info';
                break;

            case CommonConst.MSG_TYPE_CONST.SUCCESS:
                className += '--success';
                break;

            default:
                className += '--warning';
                break;
        }

        return className;
    };

    return (
        <>
            {showNotification && (
                <div className={css('toast', getNotificationClassName(type))}>
                    <div className={css('toast__icon')}>
                        <FontAwesomeIcon icon={faCheckCircle} />
                    </div>
                    <div className={css('toast__body')}>
                        <h3 className={css('toast__title')}>{CommonUtils.capitalizeFirstLetter(type)}</h3>
                        <p className={css('toast__message')}>{message}</p>
                    </div>
                    <div className={css('toast__close')} onClick={closeNotification}>
                        <FontAwesomeIcon icon={faXmark} />
                    </div>
                </div>
            )}
        </>
    );
};
