import React, { useState, useEffect } from 'react';
import styles from './SettingNote.module.scss';
import className from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove, faTrash, faAdd, faEdit, faCheck, faBan } from '@fortawesome/free-solid-svg-icons';
import NoteAPI from '~/Api/Note';
import Toast from '~/Components/Controls/Toast';
import Pagination from '~/Components/Controls/Pagination';

const css = className.bind(styles);

function SettingNote({openNote, closeNote}) {
    const [listNote, setListNote] = useState([]);
    const [idEdit, setIdEdit] = useState(0);
    const [openSettingNote, setOpenSettingNote] = useState(false);
    const [openAddNote, setOpenAddNote] = useState(false);
    const [fileImage, setFileImage] = useState(null);
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [typeToast, setTypeToast] = useState('success');
    const [showToast, setShowToast] = useState(false);
    const pageSize = 10;
    const [pageIndex, setPageIndex] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const [valueSearch, setValueSearch] = useState('');

    const handleValueSearch = (event) => {
        setValueSearch(event.target.value);
        let search = event.target.value;
        const n = new NoteAPI();
        if(search === ''){
            n.getListNoteByPage(pageIndex, pageSize)
            .then(response => {
                if(response.status === 200){
                    setListNote(response.data);
                }
            });
            n.countNote()
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            })
        } else {
            n.findNote(search, 1, pageSize)
            .then(response => {
                if(response.status === 200){
                    setListNote(response.data);
                }
            });
            n.countFindNote(search)
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            })
        }
    }
    
    const handleOpenSetting = () =>{
        if(openSettingNote){
            closeNote(false);
            setOpenSettingNote(false);
        } else {
            setOpenSettingNote(true);
            setPageIndex(1);
            const note = new NoteAPI();
            note.countNote()
            .then(response => {
                if(response.status == 200){
                    setTotalItems(response.data);
                }
            })
        }
    };

    useEffect(() => {
        if(openNote)handleOpenSetting();
    },[openNote]);

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const handleOpenAddNote = () =>{
        if(openAddNote){
            setOpenAddNote(false);
            setOpenSettingNote(true);
        } else {
            setOpenAddNote(true);
            setOpenSettingNote(false);
        }
    };

    const handleGetName = (event) => {
        setName(event.target.value);
    };

    const handleFileImageUpload = (event) => {
        setFileImage(event.target.files[0]);
    };

    const handleSubmitNote = (event) => {
        const formData = new FormData();
        formData.append('Name', name);
        formData.append('dataFile', fileImage);

        const n = new NoteAPI();
        n.uploadNote({formData})
        .then(response => {
            setName('');
            setFileImage(null);
        });
    };

    const handleRemove = (Id) => {
        const n = new NoteAPI();
        n.deleteNote(Id).then((response) => {
            fetchNote();
        });
    };

    const handleOpenEdit = (id) => {
        setIdEdit(id);
        const editingRow = listNote.find((res) => res.id === id);
        setName(editingRow?.name);
    };

    const handleSubmitEdit = (id) => {
        const formData = new FormData();
        formData.append('Id',id);
        formData.append('Name',name);

        const n = new NoteAPI;
        n.renameNote(formData)
        .then((res) => {
            if(res.status === 200){
                n.getListNoteByPage(pageIndex, pageSize)
                .then(x => {
                    if(x.status === 200){
                        setIdEdit(0);
                        setListNote(x.data);
                        setShowToast(true);
                        setMessage('Cập nhật thành công');
                        setTypeToast('success');
                    } else {
                        setShowToast(true);
                        setMessage(x.detail);
                        setTypeToast('dangerous');
                    }
                });
            } else {
                setShowToast(true);
                setMessage(res.detail);
                setTypeToast('dangerous');
            }
        })
    };

    const handlePageChange = (data) => {
        setPageIndex(data);
    };

    const fetchNote = () => {
        const n = new NoteAPI();
        if(valueSearch === ''){
            n.getListNoteByPage(pageIndex, pageSize)
            .then(response => {
                if(response.status === 200){
                    setListNote(response.data);
                }
            });
            n.countNote()
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            })
        } else {
            n.findNote(valueSearch, pageIndex, pageSize)
            .then(response => {
                if(response.status === 200){
                    setListNote(response.data);
                }
            });
            n.countFindNote(valueSearch)
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            });
        }
    };

    const handleCancel = () => {
        setIdEdit(0);
    };

    useEffect(() => {
        fetchNote();
    },[pageIndex]);
    
    useEffect(() => {
        fetchNote();
    }, []);

    return (
        <>
            <Toast
                message={message}
                onClose={handleCloseToast}
                showToast={showToast}
                type={typeToast}
                duration={2000}
            />
            {/* <button className={css('btn-upload')} onClick={handleOpenSetting}>
                <FontAwesomeIcon icon={faSliders} /> Thiết lập chú thích
            </button> */}
            {openSettingNote && (
                <>
                    <div className={css('background-form')}></div>
                    <div className={css('form-setting')}>
                        <div className={css('background-title')}>
                            <p className={css('title')}>THIẾT LẬP CHÚ THÍCH</p>
                            <FontAwesomeIcon className={css('remove')} icon={faRemove} onClick={handleOpenSetting} />
                        </div>
                        <div className={css('group-search')}>
                            <input className={css('input-search')} placeholder='Tìm kiếm ghi chú' onChange={handleValueSearch} value={valueSearch} />
                            <label className={css('label')}>Thêm ghi chú</label>
                            <button className={css('btn-add-note')} onClick={handleOpenAddNote}>
                                <FontAwesomeIcon icon={faAdd} /> Thêm ghi chú
                            </button>
                        </div>
                        
                        <div className={css('')}>
                            <table>
                                <thead>
                                    <tr>
                                        <th className={css('th-stt')}>STT</th>
                                        <th className={css('th-ten')}>Tên ghi chú</th>
                                        <th className={css('th-ha')}>Biểu tượng</th>
                                        <th className={css('th-th')}>Thao tác</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listNote.length>0&&(listNote.map((res, idx)=>(
                                        <tr>
                                            <td>{idx+1}</td>
                                            <td>{idEdit===res.id?<input className={css('input-edit-note')} value={name} onChange={handleGetName}/>:res.name}</td>
                                            <td><img className={css('item-icon')} src={res.path}/></td>
                                            {idEdit===res.id? (
                                                <td>
                                                    <FontAwesomeIcon onClick={() => handleSubmitEdit(res.id)} className={css('icon','btn-remove')} icon={faCheck} style={{color: 'green', paddingRight: '1rem'}}/>
                                                    <FontAwesomeIcon onClick={() => handleCancel()} className={css('btn-remove')} icon={faBan} style={{color: 'red'}}/>
                                                </td>
                                            ):(
                                                <td>
                                                    <FontAwesomeIcon onClick={() => handleOpenEdit(res.id)} className={css('icon','btn-remove')} icon={faEdit} style={{paddingRight: '1rem'}}/>
                                                    <FontAwesomeIcon onClick={() => handleRemove(res.id)} className={css('btn-remove')} icon={faTrash}/>
                                                </td>
                                            )}
                                            
                                        </tr>
                                    )))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination currentPage={pageIndex} pageSize={pageSize} totalItems={totalItems} onPageChange={handlePageChange}/>
                    </div>
                </>
            )}
            {openAddNote && (
                <>
                    <div className={css('background-form')}></div>
                    <div className={css('form-add-setting')}>
                        <div className={css('background-title')}>
                            <p className={css('title')}>THÊM CHÚ THÍCH</p>
                            <FontAwesomeIcon className={css('remove')} icon={faRemove} onClick={handleOpenAddNote} />
                        </div>
                        <div className={css('form-add-note')}>
                            <div className={css('item')}>
                                <p className={css('item-title')}>Tên ghi chú</p>
                                <input
                                    onChange={handleGetName}
                                    className={css('item-input')}
                                    type="input"
                                    autoComplete="off"
                                    autoCorrect="off"
                                />
                            </div>
                            <div className={css('item')}>
                                <p className={css('item-title')}>File biểu tượng</p>
                                <input
                                    onChange={handleFileImageUpload}
                                    className={css('item-input-file')}
                                    type="file"
                                />
                            </div>
                            <div className={css('btn-group')}>
                                <button onClick={handleSubmitNote} className={css('btn-success')}>
                                    Xác nhận
                                </button>
                                <button onClick={handleOpenAddNote} className={css('btn-cancel')}>
                                    Hủy
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default SettingNote;