import { useState, useContext } from 'react';
import NotificationContext from '../../Components/Notification/NotificationContext';
import Message from '../../Utils/Message';

import Dialog from '../../Components/Controls/Dialog';
import LoadingContext from '../../Components/Loading/LoadingContext';

const Home = () => {
    const { showMessage } = useContext(NotificationContext);
    const { showLoadingIndicator } = useContext(LoadingContext);

    const handleClick = () => {
        //showMessage(Message.E0003);
        showLoadingIndicator(true);
    };

    const [showDialog, setShowDialog] = useState(false);

    const handleOpenDialog = () => {
        setShowDialog(true);
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    return (
        <div>
            <Dialog show={showDialog} onHide={handleCloseDialog} title="test" body="test" />
            {/* <button
                style={{
                    width: '100px',
                    height: '20px',
                    position: 'fixed',
                    bottom: '5px',
                    left: '5px',
                    backgroundColor: 'red',
                    zIndex: '9999',
                    cursor: 'pointer',
                }}
                onClick={handleClick}
            >
                Test Loading
            </button>

            <button
                style={{
                    width: '100px',
                    height: '20px',
                    position: 'fixed',
                    bottom: '5px',
                    left: '100px',
                    backgroundColor: 'blue',
                    zIndex: '9999',
                    cursor: 'pointer',
                }}
                onClick={handleOpenDialog}
            >
                Test
            </button> */}
        </div>
    );
};

export default Home;
