import { appConfig } from '~/Config/AppConfig';
import restful from '~/Config/RestConfig';
import axios from 'axios';

const path = appConfig.API+'api/v1/file-cross/';

class FileCross {
    uploadFileCross(formData){
        return axios.post(path+'upload-file-cross',formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            },
        }).then(response=> response.data)
        .catch(error => console.log(error.response.data));
    };

    getFileCross(){
        return axios.get(path+'get-file-cross/')
        .then(response => response.data)
        .catch(error => console.log(error.response.data));
    };

    getFileCrossById(Id){
        return axios.get(path+'get-file-cross-by-id/'+Id)
        .then(response => response.data)
        .catch(error => console.log(error.response.data));
    };

    getFileCrossByPage(pageIndex, pageSize){
        return axios.get(path+`get-file-cross-by-page/${pageIndex}/${pageSize}`)
        .then(response => response.data)
        .catch(error => console.log(error.response.data));
    };

    countFileCross(){
        return axios.get(path+'count-file-cross/')
        .then(response => response.data)
        .catch(error => console.log(error.response.data));
    };

    findFileCross(keyword, pageIndex, pageSize){
        return axios.get(path+`find-file-cross/${keyword}/${pageIndex}/${pageSize}`)
        .then(response => response.data)
        .catch(error => console.log(error.response.data));
    };

    countFindFileCross(keyword){
        return axios.get(path+`count-find-file-cross/${keyword}`)
        .then(response => response.data)
        .catch(error => console.log(error.response.data));
    };
}

export default FileCross;