import { appConfig } from '~/Config/AppConfig';
import restful from '~/Config/RestConfig';
import axios from 'axios';

const path = appConfig.API+'api/v1/search/';
class Search{
    getSearchByKeyword(Keyword){
        return axios.get(path+`get-search-by-keyword/${Keyword}/1/5`)
        .then(response => response.data)
        .catch(error => console.log(error.response.data));
    };
};

export default Search;