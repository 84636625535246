import React, { useState, useEffect } from 'react';
import styles from './Toast.module.scss';
import className from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCheckCircle, faWarning, faRemove, faClose } from '@fortawesome/free-solid-svg-icons';

const css = className.bind(styles);

const Toast = ({ message, onClose, showToast, duration = 3000, type='information' }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (showToast) {
      handleShowToast();
    }
  }, [showToast]);

  const handleShowToast = () => {
    setProgress(0);

    const timer = setInterval(() => {
        setProgress((prevProgress) => {
          const increment = (10 / duration) * 100;
          return Math.min(prevProgress + increment, 100);
        });
      }, 10);

    setTimeout(() => {
      clearInterval(timer);
      onClose();
    }, duration);
  };

  return (
    <div className={css('toast-container',`${showToast ? 'show' : 'hide'}`)}>
        <div className={css('toast', `${type}`)}>
            <FontAwesomeIcon icon={type==='warning'?faWarning:type==='success'?faCheckCircle:type==='dangerous'?faRemove:faInfoCircle} className={css('icon',`${type}`)}/>
            <p className={css('toast-message')}>{message}</p>
            {/* <FontAwesomeIcon icon={faClose} className={css('toast-close',`${type}`)} onClick={onClose}/> */}
        </div>
        {progress && <div className={css('progress-bar', `${type}-background`)} style={{ width: `${progress}%` }} />}
    </div>
  );
};

export default Toast;