import { useContext } from 'react';
import axios from 'axios';
import store from 'store';
import { appConfig } from './AppConfig';
import Message from '~/Utils/Message';
import NotificationContext from '~/Components/Notification/NotificationContext';
//import LoadingContext from '~/Components/Loading/LoadingContext';

let restful = axios.create({
    baseURL: appConfig.SERVER_API_URL,
    timeout: appConfig.API_TIMEOUT,
});

//const { showLoadingIndicator } = useContext(LoadingContext);

restful.interceptors.request.use(
    (config) => {
        // Show loading
        //showLoadingIndicator(true);
        //restful.defaults.headers.common['Authorization'] = `Bearer ${store.get('token')}`;
        return config;
    },
    (error) => {
        console.log('Loi request: '+ error)
        return Promise.reject(error);
    },
);

restful.interceptors.response.use(
    (response) => {
        //Hiden loading
        //showLoadingIndicator(false);

        return response;
    },
    (error) => {
        console.log('Loi response: '+ error.response)
        const { showMessage } = useContext(NotificationContext);

        if (error.config.hasOwnProperty('errorHandler') && error.config.errorHandler === false) {
            return Promise.reject(error);
        }

        if (!(error.response && error.response.status)) {
            // Notification error
            showMessage(Message.E0001);
        } else {
            switch (error.response.status) {
                case 401:
                    showMessage(Message.E0002);
                    break;

                default:
                    showMessage(Message.E0003);
                    break;
            }
        }

        return Promise.reject(error);
    },
);

export default restful;
