import React, { useEffect, useState } from 'react';
import className from 'classnames/bind';
import styles from './Solution.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove, faAdd, faTrash, faEdit, faCheck, faBan } from '@fortawesome/free-solid-svg-icons';
import SolutionAPI from '~/Api/Solution';
import Toast from '~/Components/Controls/Toast';
import Pagination from '~/Components/Controls/Pagination';

const css = className.bind(styles);

function Solution({openSolution, closeSolution}){
    const [listSolution, setListSolution] = useState([]);
    const [message, setMessage] = useState('');
    const [typeToast, setTypeToast] = useState('success');
    const [showToast, setShowToast] = useState(false);
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const [position, setPosition] = useState('');
    const [constructionType, setConstructionType] = useState('');
    const [unitPrice, setUnitPrice] = useState('');
    const [idEdit, setIdEdit] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const pageSize = 10;
    const [pageIndex, setPageIndex] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const [valueSearch, setValueSearch] = useState('');

    useEffect(() => {
        if(openSolution){
            handleOpenSolution();
            // const solution = new SolutionAPI();
            // solution.getSolutionByPage(pageIndex, pageSize)
            // .then((respon) => {
            //     if(respon.status == 200){
            //         setListSolution(respon.data);
            //     } else {
            //         setShowToast(true);
            //         setMessage(respon.title);
            //         setTypeToast('dangerous');
            //     }
            // });
        }   
    },[openSolution]);

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const getValuePosition = (event) => {
        setPosition(event.target.value);
    };

    const getValueConstructionType = (event) => {
        setConstructionType(event.target.value);
    };

    const getValuePrice = (event) => {
        setUnitPrice(event.target.value);
    };

    const openAdd = () => {
        setIsOpenAdd(true);
    };

    const closeAdd = () => {
        setIsOpenAdd(false);
        FuncDefault();
    };

    const openEdit = (id) => {
        const editingRow = listSolution.find((res) => res.id === id);
        setPosition(editingRow?.position || '');
        setConstructionType(editingRow?.constructionType || '');
        setUnitPrice(editingRow?.unitPrice || '');
        setIdEdit(id);
    };

    const closeEdit = () => {
        setIdEdit(0);
        FuncDefault();
    };

    const handleOpenSolution = () => {
        setIsOpen(!isOpen);
        if(!isOpen){
            closeSolution(false);
            setPageIndex(1);
            const solution = new SolutionAPI();
            solution.countSolution()
            .then(response => {
                if(response.status == 200){
                    setTotalItems(response.data);
                }
            })
        }
    };

    const handleEdit = (id) => {
        const formData = new FormData();
        formData.append('Id',id);
        formData.append('Position',position);
        formData.append('ConstructionType',constructionType);
        formData.append('UnitPrice',unitPrice);

        const solution = new SolutionAPI();
        solution.updateSolution(formData)
        .then((respon) => {
            if(respon.status == 200) {
                setShowToast(true);
                setMessage('Chỉnh sửa thành công');
                setTypeToast('success');
                reload();
                closeEdit();
            } else {
                setShowToast(true);
                setMessage(respon.title);
                setTypeToast('dangerous');
            }
        });
    };

    const handleRemove = (id) => {
        const solution = new SolutionAPI();
        solution.deleteSolution(id)
        .then((respon) => {
            if(respon.status == 200){
                setShowToast(true);
                setMessage('Đã xóa thành công');
                setTypeToast('success');
                reload();
            } else {
                setShowToast(true);
                setMessage(respon.title);
                setTypeToast('dangerous');
            }
        });
    };

    const handleSuccessSolution = () => {
        const formData = new FormData();
        formData.append('Position',position);
        formData.append('ConstructionType',constructionType);
        formData.append('UnitPrice',unitPrice);

        const solution = new SolutionAPI();
        solution.addSolution(formData)
        .then((respon) => {
            if(respon.status == 200){
                setShowToast(true);
                setMessage('Cập nhật thành công');
                setTypeToast('success');
                reload();
                closeEdit();
            } else {
                setShowToast(true);
                setMessage(respon.title);
                setTypeToast('dangerous');
            }
        });
    };

    const FuncDefault = () => {
        setIsOpenAdd(false);
        setPosition('');
        setConstructionType('');
        setUnitPrice('');
    };

    const reload = () => {
        const solution = new SolutionAPI();
        solution.getSolutionByPage(pageIndex, pageSize)
        .then((respon) => {
            if(respon.status == 200){
                FuncDefault();
                setListSolution(respon.data);
            } else {
                setShowToast(true);
                setMessage(respon.title);
                setTypeToast('dangerous');
            }
        });
    };

    const handlePageChange = (data) => {
        setPageIndex(data);
    };

    const fetchSolution = () => {
        const solution = new SolutionAPI();
        solution.getSolutionByPage(pageIndex, pageSize)
        .then((respon) => {
            if(respon.status == 200){
                setListSolution(respon.data);
            } else {
                setShowToast(true);
                setMessage(respon.title);
                setTypeToast('dangerous');
            }
        });
    };

    const handleValueSearch = (event) => {
        setValueSearch(event.target.value);
        const solution = new SolutionAPI();
        let search = event.target.value;
        if(search === ''){
            solution.getSolutionByPage(pageIndex, pageSize)
            .then((respon) => {
                if(respon.status === 200){
                    setListSolution(respon.data);
                } else {
                    setShowToast(true);
                    setMessage(respon.title);
                    setTypeToast('dangerous');
                }
            });
            solution.countSolution()
            .then(respon => {
                if(respon.status === 200){
                    setTotalItems(respon.data);
                }
            });
        } else {
            solution.findSolution(search, pageIndex, pageSize)
            .then((respon) => {
                if(respon.status === 200){
                    setListSolution(respon.data);
                }
            });
            solution.countFindSolution(search)
            .then(respon => {
                if(respon.status === 200){
                    setTotalItems(respon.data);
                }
            })
        }
    };

    useEffect(() => {
        fetchSolution();
    },[pageIndex]);

    useEffect(() => {
        const solution = new SolutionAPI();
        solution.getSolutionByPage(pageIndex, pageSize)
        .then((respon) => {
            if(respon.status == 200){
                setListSolution(respon.data);
            } else {
                setShowToast(true);
                setMessage(respon.title);
                setTypeToast('dangerous');
            }
        });
    },[]);

    return (<>
        <Toast
            message={message}
            onClose={handleCloseToast}
            showToast={showToast}
            type={typeToast}
            duration={2000}
        />
        {/* <button className={css('btn-upload')} onClick={handleOpenSolution}>
            <FontAwesomeIcon icon={faHammer}/> Danh sách giải pháp
        </button> */}
        {isOpen && (
            !isOpenAdd && (
                <>
                    <div className={css('background-form')}></div>
                    <div className={css('form-solution')}>
                        <div className={css('background-title')}>
                            <p className={css('title')}>Danh sách giải pháp</p>
                            <FontAwesomeIcon className={css('remove')} icon={faRemove} onClick={handleOpenSolution}/>
                        </div>
                        <div className={css('group-search')}>
                            <input className={css('input-search')} placeholder='Tìm kiếm giải pháp' onChange={handleValueSearch} value={valueSearch} />
                            <label className={css('label')}>Thêm giải pháp</label>
                            <button className={css('btn-add-note')} onClick={() => openAdd()}><FontAwesomeIcon icon={faAdd}/> Thêm giải pháp</button>
                        </div>
                        <div className={css('form-list-solution')}>
                            <div className={css('list-solution')}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>STT</th>
                                            {/* <th>Vị trí</th> */}
                                            <th>Loại công trình</th>
                                            <th>Đơn giá</th>
                                            <th>Thao tác</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listSolution.map((res, idx) =>
                                            <tr>
                                                <td>{idx+1}</td>
                                                {/* <td>{res.id==idEdit?<input className={css('input-edit')} value={position} onChange={getValuePosition}/>:res.position}</td> */}
                                                <td>{res.id==idEdit?<input className={css('input-edit')} value={constructionType} onChange={getValueConstructionType}/>:res.constructionType}</td>
                                                <td>{res.id==idEdit?<input className={css('input-edit')} value={unitPrice} onChange={getValuePrice}/>:res.unitPrice}</td>
                                                {res.id===idEdit?( 
                                                    <td className={css('action')}>
                                                        <FontAwesomeIcon onClick={() => handleEdit(res.id)} className={css('icon','btn-remove')} icon={faCheck} style={{color: 'green'}}/>
                                                        <FontAwesomeIcon onClick={() => closeEdit()} className={css('icon','btn-remove')} icon={faBan} style={{color: 'red'}}/>
                                                    </td>
                                                ):(
                                                    <td className={css('action')}>
                                                        <FontAwesomeIcon className={css('icon','btn-remove')} icon={faEdit} onClick={() => openEdit(res.id)}/>
                                                        <FontAwesomeIcon onClick={() => handleRemove(res.id)} className={css('icon','btn-remove')} icon={faTrash}/>
                                                    </td>
                                                )}
                                            </tr>)}  
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Pagination  currentPage={pageIndex} pageSize={pageSize} totalItems={totalItems} onPageChange={handlePageChange}/>
                    </div>
                </>
        ))}
        {isOpen && (
            isOpenAdd && (
                <>
                    <div className={css('background-form')}></div>
                    <div className={css('form-add')}>
                        <div className={css('background-title')}>
                            <p className={css('title')}>Thêm giải pháp</p>
                            <FontAwesomeIcon className={css('remove')} icon={faRemove} onClick={closeAdd}/>
                        </div>
                        <div className={css('form-add-solution')}>
                            <div className={css('item')}>
                                <label className={css('label')}>Vị trí</label>
                                <input className={css('input')} onChange={getValuePosition} value={position}/>
                            </div>
                            <div className={css('item')}>
                                <label className={css('label')}>Loại công trình</label>
                                <input className={css('input')} onChange={getValueConstructionType} value={constructionType}/>
                            </div>
                            <div className={css('item')}>
                                <label className={css('label')}>Giá tiền</label>
                                <input className={css('input')} onChange={getValuePrice} value={unitPrice}/>
                            </div>
                            <div className={css('item', 'btn-group')}>
                                <button className={css('btn-success')} onClick={handleSuccessSolution}>Lưu</button>
                                <button className={css('btn-cancel')} onClick={closeAdd}>Hủy</button>
                            </div>
                        </div>
                    </div>
                </>
            )
        )}
    </>);
}

export default Solution;