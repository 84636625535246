const CommonConst = {
    MSG_TYPE_CONST: {
        SUCCESS: 'success',
        INFO: 'info',
        ERROR: 'error',
        WARNING: 'warning',
    },
};

export default CommonConst;
