import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import className from 'classnames/bind';
import styles from './Tree.module.scss';
import File from '~/Api/File';
import PopupMenu from '~/Components/Controls/PopupMenu';
import Toast from '~/Components/Controls/Toast';

const css = className.bind(styles);

function Tree({dataTree, onDataChange, listIdFile }) {
  const [idChecked, setIdChecked] = useState([]);
  const [idFileChecked, setIdFileChecked] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [checkedListFile, setCheckedListFile] = useState({});
  const [checkedListFolderFullChild, SetCheckedListFolderFullChild] = useState({});
  const [listFile, setListFile] = useState([]);
  const [position, setPosition] = useState(null);
  const [valueName, setValueName] = useState('');
  const inputRef = useRef(null);
  const [message, setMessage] = useState('');
  const [typeToast, setTypeToast] = useState('success');
  const [showToast, setShowToast] = useState(false);

  const handleCloseToast = () => {
    setShowToast(false);
  };
  
  let transformedData;
  if(dataTree){
    transformedData = buildFolderStructure(dataTree);
  }

  useEffect(() => {
    const file = new File();
    file.getFiles()
    .then(response => {
      if(response.status === 200){
        setListFile(response.data);
      } else {
        setMessage(response.detail);
        setTypeToast('dangerous');
        setShowToast(true);
      }
    });
  }, [dataTree]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideInputClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideInputClick);
    };
  }, []);
  
  function buildFolderStructure(data) {
    const folderMap = {};
    const folderTree = [];   
    
    data.forEach((folder) => {
      folder.folderChild = [];
      folderMap[folder.id] = folder;
    });
    
    data.forEach((folder) => {
      const parent = folderMap[folder.rootId];
      if (parent) {
        parent.folderChild.push(folder);
      } else {
        folderTree.push(folder);
      }
    });
    return folderTree;
  };

  const handleCheckboxChange = (event, itemId, folderData) => {
    const isChecked = event.target.checked;
    setPosition(null);
    checkedChild(isChecked?0:1, itemId);
    setCheckedItems((prevCheckedItems) => {
      const updatedCheckedItems = { ...prevCheckedItems };
      if (isChecked) {
        updatedCheckedItems[itemId] = true;
        funcsCheckFolder(itemId);
      } else {
        let listIdChecked = [];
        delete updatedCheckedItems[itemId];
        setIdChecked((prevIdChecked) => {
          const newArray = prevIdChecked.filter((x) => x !== itemId);
          onDataChange(newArray);
          listIdChecked = newArray;
          return newArray;
        });
        const resListFiles = listFile.filter((x) => x.folderId === itemId);
        resListFiles.map((res) => {
          setCheckedListFile((prevCheckedListFile) => {
            const updatedCheckedListFile = { ...prevCheckedListFile };
            if (!isChecked) {
              delete updatedCheckedListFile[res.id];
              setIdFileChecked((prevIdFileChecked) => {
                const newArray = prevIdFileChecked.filter((x) => x !== res.id);
                listIdFile(newArray);
                checkEveryChildOnFolder(1, itemId, listIdChecked, newArray);
                return newArray;
              });
            }
            return updatedCheckedListFile;
          });
        })
      }
      return updatedCheckedItems;
    });
  };

  const funcsCheckFolder = (FolderId) => {
    setIdChecked((prevIdChecked) => {
      const newArray = [...prevIdChecked, FolderId];
      onDataChange(newArray);
      const resListFiles = listFile.filter((x) => x.folderId===FolderId);
      resListFiles.map((res) => {
        setCheckedListFile((prevCheckedListFile) => {
          const updatedCheckedListFile = { ...prevCheckedListFile };
          updatedCheckedListFile[res.id] = true;
          setIdFileChecked((prevIdFileChecked) => {
            const newArray1 = [...prevIdFileChecked, res.id];
            listIdFile(newArray1);
            checkEveryChildOnFolder(0, FolderId, newArray, newArray1);
            checkParentFolder(FolderId);
            return newArray1;
          });
          return updatedCheckedListFile;
        });
      });
      return newArray;
    });
  }

  const checkParentFolder = (FolderId) => {
    const parentFolder = dataTree.filter((x) => {
      if(x.id === FolderId){
        const folder = dataTree.filter((y) => y.id === x.rootId);
        return folder[0];
      }
    });
    parentFolder.map((x) => {
      setCheckedItems((prevCheckedItems) => {
        const updatedCheckedItems = { ...prevCheckedItems };
        updatedCheckedItems[x.rootId] = true;
        funcsCheckFolder(x.rootId);
        return updatedCheckedItems;
      });
    });
  }

  const getAllChildItems = (item) => {
    let childItems = [];
    if (item.folderChild && item.folderChild.length > 0) {
      childItems = item.folderChild.reduce((acc, childItem) => {
        acc.push(childItem, ...getAllChildItems(childItem));
        return acc;
      }, []);
    }
    return childItems;
  };

  const renderTreeItem = (item, level = 0, isExpanded = true ) => {
    const hasChildren = item.folderChild && item.folderChild.length > 0;
    return (
      <div key={item.id} style={{paddingLeft: `${(level+2)/10}rem`}}>
        <label style={{fontWeight: '600', cursor: 'Pointer'}}>
          <FontAwesomeIcon style={{width: '1rem', height: '1rem', padding: '0.3rem'}} icon={checkedItems[item.id]?faChevronDown:faChevronRight}/>
          {' '}
          <input
            id=''
            className={css(`regular-checkbox-folder${checkedListFolderFullChild!=null&&Object.keys(checkedListFolderFullChild).length>0?checkedListFolderFullChild[item.id]!=null?'-1':'':''}`)}
            type="checkbox"
            checked={checkedItems[item.id] || false}
            onChange={(event) => handleCheckboxChange(event, item.id, dataTree)}
          />
          <img src='/Assets/Images/icon_folder.png' style={{width: '2rem', height: '2rem', marginLeft: '0.4rem', marginRight: '0.2rem', paddingTop: '0.2rem'}} />
          {item.foldersName}
        </label>
        {checkedItems[item.id]&&(renderFileByFolder(item.id, level))}
        {hasChildren && (isExpanded || checkedItems[item.id]) && (
          <div>
            {item.folderChild.map((childItem) =>
              renderTreeItem(childItem, (level+10), isExpanded)
            )}
          </div>
        )}
      </div>
    );
  };

  const handleCheckboxChangeFile = (event, itemId, FolderId) =>{
    const isChecked = event.target.checked;
    setPosition(null);
    setCheckedListFile((prevCheckedListFile) => {
      const updatedCheckedListFile = { ...prevCheckedListFile };
      if (isChecked) {
        updatedCheckedListFile[itemId] = true;
        checkParentFolder(FolderId);
        setIdFileChecked((prevIdFileChecked) => {
          const newArray = [...prevIdFileChecked, itemId];
          listIdFile(newArray);
          const resListFiles = listFile.filter((x) => x.folderId===FolderId);
          const hasCommonElement = resListFiles.some((obj1) => {
            return newArray.includes(obj1.id);
          });
          if (hasCommonElement) {
            setCheckedItems((prevCheckedItems) => {
                const updatedCheckedItems = { ...prevCheckedItems };
                updatedCheckedItems[FolderId] = true;
                setIdChecked((prevIdChecked) => {
                  const newArray1 = [...prevIdChecked, FolderId];
                  checkEveryChildOnFolder(0, FolderId, newArray1, newArray);
                  onDataChange(newArray1);
                  return newArray1;
                });
              return updatedCheckedItems;
            })
          }
          return newArray;
        });
      } else {
        delete updatedCheckedListFile[itemId];
        setIdFileChecked((prevIdFileChecked) => {
          const newArray = prevIdFileChecked.filter((x) => x !== itemId);
          listIdFile(newArray);
          const resListFiles = listFile.filter((x) => x.folderId===FolderId);
          const hasCommonElement = resListFiles.some((obj1) => {
            return newArray.includes(obj1.id);
          });
          setIdChecked((prevIdChecked) => {
            checkEveryChildOnFolder(1, FolderId, prevIdChecked, newArray);
            const newArray1 = prevIdChecked.filter((x) => x !== FolderId);
            const folders = dataTree.filter((x) => x.rootId === FolderId);
            const isUncheckedFolderChill = folders.some((obj) => {
              return newArray1.includes(obj.id);
            })
            if(!hasCommonElement&&!isUncheckedFolderChill){
              onDataChange(newArray1);
              setCheckedItems((prevCheckedItems) => {
                const updatedCheckedItems = { ...prevCheckedItems };
                delete updatedCheckedItems[FolderId];   
                return updatedCheckedItems;
              })
              return newArray1;
            } else {
              return prevIdChecked;
            }
          });
          return newArray;
        });  
      }
      return updatedCheckedListFile;
    })
  };

  const [isOpenInputFile, setIsOpenInputFile] = useState(false);
  const [isChangeValueInput, setIsChangeValueInput] = useState(false);
  const [idInput, setIdInput] = useState(null);

  const handleGetValue = (event) => {
    if(event){
      setIsChangeValueInput(true);
    };
    setValueName(event.target.value);
  };

  const handleEnterInput = (event) => {
    if(event.key === 'Enter'){
      funcRename();
    }
  };

  const checkedChild = (type, FolderId) => {
    if(type===0) {
      let listFolder = dataTree.filter((x) => x.rootId === FolderId);
      let arrayListFolders = [];
      let arrayListFiles = [];
      listFolder.map((res) => {
        setCheckedItems((prevCheckedItems) => {
          const updatedCheckedItems = { ...prevCheckedItems };
          updatedCheckedItems[res.id] = true;
          setIdChecked((prevIdChecked) => {
            const newArray = [...prevIdChecked, res.id];
            arrayListFolders = newArray;
            const resListFiles = listFile.filter((x) => x.folderId===res.id);
            resListFiles.map((res1) => {
              setCheckedListFile((prevCheckedListFile) => {
                const updatedCheckedListFile = { ...prevCheckedListFile };
                updatedCheckedListFile[res1.id] = true;
                setIdFileChecked((prevIdFileChecked) => {
                  const newArray1 = [...prevIdFileChecked, res1.id];
                  arrayListFiles = newArray1;
                  checkEveryChildOnFolder(0, res.id, newArray, newArray1);
                  return newArray1;
                });
                return updatedCheckedListFile;
              });
            });
            return newArray;
          }) 
          return updatedCheckedItems;
        })
      });
      onDataChange(arrayListFolders);
      listIdFile(arrayListFiles);
    } else {
      uncheckedAll(FolderId);
    }
  }

  const uncheckedAll = (FolderId) => {
    let arrayListFolders = [];
    let arrayListFiles = [];
    let listFolder = dataTree.filter((x) => x.rootId === FolderId);
    if(listFolder.length>0){
      listFolder.map((res) => {
        setCheckedItems((prevCheckedItems) => {
          const updatedCheckedItems = { ...prevCheckedItems };
          delete updatedCheckedItems[res.id];
          setIdChecked((prevIdChecked) => {
            const newArray = prevIdChecked.filter((x) => x !== res.id);
            arrayListFolders = newArray;
            const resListFiles = listFile.filter((x) => x.folderId===res.id);
            resListFiles.map((res1) => {
              setCheckedListFile((prevCheckedListFile) => {
                const updatedCheckedListFile = { ...prevCheckedListFile };
                delete updatedCheckedListFile[res1.id];
                setIdFileChecked((prevIdFileChecked) => {
                  const newArray1 = prevIdFileChecked.filter((x) => x !== res1.id);
                  arrayListFiles = newArray1;
                  checkEveryChildOnFolder(0, FolderId, newArray, newArray1);
                  uncheckedAll(res.id)
                  return newArray1;
                });
                return updatedCheckedListFile;
              });
            });
            return newArray;
          }) 
          return updatedCheckedItems;
        });
      });
    }
    onDataChange(arrayListFolders);
    listIdFile(arrayListFiles);
  }

  const checkEveryChildOnFolder = (type, FolderId, listIdChecked, listIdFileChecked) => {
    if(type===0) {
      let checkedFile = false;
      let checkedFolder = false;
      if(listIdChecked&&listIdFileChecked){
        const resListFiles = listFile.filter((x) => x.folderId===FolderId);
        checkedFile = resListFiles.every(item => listIdFileChecked.includes(item.id));
        const resListFolder = dataTree.filter((x) => x.rootId===FolderId);
        if(resListFolder.length>0){
          checkedFolder = resListFolder.every(item => listIdChecked.includes(item.id));
        } else {
          checkedFolder = true;
        }
        if(checkedFile&&checkedFolder){
          SetCheckedListFolderFullChild((prevListFolderFullChild) => {
            const updatedCheckedListFile = { ...prevListFolderFullChild };
            updatedCheckedListFile[FolderId] = true;
            return updatedCheckedListFile;
          })
        }
      }
    } else {
      let root = dataTree.filter((x) => x.id===FolderId);
      SetCheckedListFolderFullChild((prevListFolderFullChild) => {
        const updatedCheckedListFile = { ...prevListFolderFullChild };
        delete updatedCheckedListFile[FolderId];
        return updatedCheckedListFile;  
      });
      if(root[0].rootId!=0){
        SetCheckedListFolderFullChild((prevListFolderFullChild) => {
          const updatedCheckedListFile = { ...prevListFolderFullChild };
          delete updatedCheckedListFile[root[0].rootId];
          return updatedCheckedListFile;  
        });
      } 
    }
  }

  const renderFileByFolder = (FolderId, level) =>{
    return (listFile && 
      listFile.map((res, idx) => 
        res.folderId === FolderId && 
          (<>
            <div key={res.id} className={css('item-file')} style={{paddingLeft: `${(level+20)/10}rem`}}>
              <label className={css('label-container-file')} onContextMenu={(e)=>handleMouseDown(e, res.id)}>
                <input
                  id='checkbox-file'
                  className={css('regular-checkbox-file')}
                  type="checkbox"
                  checked={checkedListFile[res.id]||false}
                  onChange={(event) => handleCheckboxChangeFile(event, res.id, FolderId)}
                  styles={styles}
                />
                <img src='/Assets/Images/icon_file.png' style={{width: '2rem', height: '2rem', marginRight: '1rem', marginLeft: '1rem', cursor: 'Pointer'}} />
                {isOpenInputFile && idInput===res.id? (
                    <input ref={inputRef} type='input' className={css('input-file')} idFile={res.id} onChange={handleGetValue} value={!isChangeValueInput&&res.nameFile?res.nameFile:valueName} onKeyPress={(e) => handleEnterInput(e)}/>
                ):(<p className={css('label-file')} style={{cursor: 'Pointer'}}>{res.nameFile?res.nameFile:'Không xác định'}</p>)}
              </label>
            </div>
            <PopupMenu position={position} rename={(e) => handleOpenRename(e)} deleteFile={handleDelete}/>
          </>) 
      ));
  };

  const handleOpenRename = (data) => {
    setIsOpenInputFile(data.open);
    setIdInput(data.id);
  };

  const handleDelete = (data) => {
    const file = new File();
    file.deleteFile(data.id)
    .then(response => {
      if(response.status === 200){
        file.getFiles()
        .then(response1 => {
          if(response1.status === 200){
            setMessage('Xóa thành công!');
            setTypeToast('success');
            setShowToast(true);
            setListFile(response1.data);
          } else {
            setMessage(response1.detail);
            setTypeToast('dangerous');
            setShowToast(true);
          }
        })
      } else {
        setMessage(response.detail);
        setTypeToast('dangerous');
        setShowToast(true);
      }
    })
  };

  const handleMouseDown = (event, id) => {
    const object = { id: id, x: event.clientX, y: event.clientY };
    setPosition(object);
  };

  const handleOutsideInputClick = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      funcRename();
    }
  };

  const funcRename = () => {
    const formData = new FormData();
      formData.append('Id', inputRef.current.getAttribute('idfile'));
      formData.append('Name', inputRef.current.value);
      const file = new File();
      file.updateRenameFile(formData)
      .then(response => {
        if(response.status === 200){
          setMessage('Đổi tên thành công!');
          setTypeToast('success');
          setShowToast(true);
          file.getFiles()
          .then(response1 => {
            if(response1.status === 200){
              setListFile(response1.data);
            } else {
              setMessage(response1.detail);
              setTypeToast('dangerous');
              setShowToast(true);
            }
          })
        } else {
          setMessage(response.detail);
          setTypeToast('dangerous');
          setShowToast(true);
        }
        setValueName('');
      })       
      setIsOpenInputFile(false);
      setIsChangeValueInput(false); 
  }

  return (
    <>
      <Toast
        message={message}
        onClose={handleCloseToast}
        showToast={showToast}
        type={typeToast}
        duration={2000}
      />
      <div className={css('map-tree')}>
        {transformedData.map((item) => renderTreeItem(item))}
      </div>
    </>  
  );
};

export default Tree;