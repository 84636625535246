import React, { useState, useEffect, useRef } from 'react';
import className from 'classnames/bind';
import styles from './User.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faRemove, faCheck, faBan, faEdit, faTrash, faAdd, faSearch } from '@fortawesome/free-solid-svg-icons';
import UserAPI from '~/Api/User';
import Register from '~/Components/Register';
import Pagination from '~/Components/Controls/Pagination';
import Toast from '~/Components/Controls/Toast';

const css = className.bind(styles);

const User = ({openUser, closeUser}) => {
    const [isOpenList, setIsOpenList] = useState(false);
    const pageSize = 10;
    const [users, setUsers] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const [idEdit, setIdEdit] = useState(0);
    const [valueSearch, setValueSearch] = useState('');
    const [valueTen, setValueTen] = useState('');
    const [valueUserName, setValueUserName] = useState('');
    const [valueRoleName, setValueRoleName] = useState('');
    const [valueEmail, setValueEmail] = useState('');
    const [valuePhoneNumber, setValuePhoneNumber] = useState('');
    const [isOpenRegister, setIsOpenRegister] = useState(false);
    const [message, setMessage] = useState('');
    const [typeToast, setTypeToast] = useState('success');
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        if(openUser){handleOpenSolution()};
    },[openUser]);

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const handleOpenSolution = () => {
        const user = new UserAPI();
        setIsOpenList(!isOpenList);
        setValueSearch('');
        if(isOpenList === false){
            setPageIndex(1);
            user.GetUser(pageIndex, pageSize)
            .then(response => {
                if(response.status === 200){
                    setUsers(response.data);
                }
            });
            user.countUser()
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            });
        }
        if(isOpenList){closeUser(false);}
    };

    const fetchUsers = async () => {
        const user = new UserAPI();
        if(valueSearch === ''){
            user.GetUser(pageIndex, pageSize)
            .then(response => {
                if(response.status === 200){
                    setUsers(response.data);
                }
            });
            user.countUser(pageIndex, pageSize)
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            });
        } else {
            user.findUser(valueSearch, pageIndex, pageSize)
            .then(response => {
                if(response.status === 200){
                    setUsers(response.data);
                }
            });
            user.countFindUser(valueSearch)
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            });
        }
    };

    const handleValueSearch = (event) => {
        const user = new UserAPI();
        setValueSearch(event.target.value);
        let search = event.target.value;
        if(search === ''){
            user.GetUser(1, pageSize)
            .then(response => {
                if(response.status === 200){
                    setUsers(response.data);
                }
            });
            user.countUser(1, pageSize)
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            });
        } else {
            user.findUser(search, pageIndex, pageSize)
            .then(response => {
                if(response.status === 200){
                    setUsers(response.data);
                }
            });
            user.countFindUser(search)
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            });
        }
    };

    const handleValueTen = (event) => {
        setValueTen(event.target.value);
    };

    const handleValueUserName = (event) => {
        setValueUserName(event.target.value);
    };

    const handleValueRoleName = (event) => {
        setValueRoleName(event.target.value);
    };

    const handleValueEmail = (event) => {
        setValueEmail(event.target.value);
    };

    const handleValuePhoneNumber = (event) => {
        setValuePhoneNumber(event.target.value);
    };

    const handlePageChange = (data) => {
        setPageIndex(data);
    };

    const handleEdit = () => {
        const formData = new FormData();
        formData.append('UserId',idEdit);
        formData.append('Ten',valueTen);
        formData.append('UserName',valueUserName);
        formData.append('RoleName',valueRoleName);
        formData.append('Email',valueEmail);
        formData.append('PhoneNumber',valuePhoneNumber);
        const user = new UserAPI();
        user.updateUser(formData)
        .then(response => {
            if(response.status == 200){
                user.GetUser(pageIndex,pageSize)
                .then(response => {
                    if(response.status === 200){
                        setUsers(response.data);
                        setShowToast(true);
                        setMessage('Cập nhật thành công');
                        setTypeToast('success');
                    } else {
                        
                    }
                })
                setIdEdit(0);
            } else {

            }
        })
    };

    const closeEdit = () => {
        setIdEdit(0);
        setValueTen('');
        setValueUserName('');
        setValueRoleName('');
        setValueEmail('');
        setValuePhoneNumber('');
    };

    const openEdit = (id) => {
        setIdEdit(id);
        const editingRow = users.find((res) => res.id === id);
        setValueTen(editingRow?.ten || '');
        setValueUserName(editingRow?.userName || '');
        setValueRoleName(editingRow?.roleName || '');
        setValueEmail(editingRow?.email || '');
        setValuePhoneNumber(editingRow?.phoneNumber || '');
    };

    const handleRemove = (id) => {
        const user = new UserAPI();
        user.deleteUser(id)
        .then(response => {
            if(response.status == 200){
                user.GetUser(pageIndex,pageSize)
                .then(response => {
                    if(response.status === 200){
                        setUsers(response.data);
                        setShowToast(true);
                        setMessage('Xóa thành công');
                        setTypeToast('success');
                    }
                })
                setIdEdit(0);                                                              
            }
        })
    };

    const handleOpenRegister = () => {
        setIsOpenRegister(true);
    };

    const handleCloseRegister = (data) => {
        setIsOpenRegister(data);
    };

    useEffect(() => {
        fetchUsers();
    },[pageIndex]);

    return (<>
        <Toast
            message={message}
            onClose={handleCloseToast}
            showToast={showToast}
            type={typeToast}
            duration={2000}
        />
        {/* <button className={css('btn-user')} onClick={handleOpenSolution}>
            <FontAwesomeIcon icon={faUsers}/> Danh sách tài khoản
        </button> */}
        {isOpenList && !isOpenRegister && (
            <>
                <div className={css('background-form')}></div>
                <div className={css('form-user')}>
                    <div className={css('background-title')}>
                        <p className={css('title')}>Danh sách tài khoản</p>
                        <FontAwesomeIcon className={css('remove')} icon={faRemove} onClick={handleOpenSolution}/>
                    </div>
                    <div className={css('form-item-user')}>
                        <div className={css('group-tools')}>
                            <div className={css('form-input-search')}>
                                <input className={css('input-search')} placeholder='Tìm kiếm người dùng' value={valueSearch} onChange={handleValueSearch}/>
                            </div>
                            <button className={css('btn-add')} onClick={handleOpenRegister}><FontAwesomeIcon icon={faAdd}/> Thêm giải pháp</button>
                        </div>
                        <div className={css('table-container')}>
                            <table className={css('table')}>
                                <thead>
                                    <tr>
                                        <th style={{width: '2%'}}>STT</th>
                                        <th>Họ tên</th>
                                        <th>Tài khoản</th>
                                        <th style={{width: '10%'}}>Vai trò</th>
                                        <th>Email</th>
                                        <th>Số điện thoại</th>
                                        <th style={{width: '10%'}}>Thao tác</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users && users.map((res, idx) => 
                                        <tr>
                                            <td>{(pageIndex - 1)*pageSize+idx+1}</td>
                                            <td>{res.id===idEdit?<input className={css('input-edit')} value={valueTen} onChange={handleValueTen}/>:res.ten}</td>
                                            <td>{res.id===idEdit?<input className={css('input-edit')} value={valueUserName} onChange={handleValueUserName}/>:res.userName}</td>
                                            <td>{res.id===idEdit?<input className={css('input-edit')} value={valueRoleName} onChange={handleValueRoleName}/>:res.roleName}</td>
                                            <td>{res.id===idEdit?<input className={css('input-edit')} value={valueEmail} onChange={handleValueEmail}/>:res.email}</td>
                                            <td>{res.id===idEdit?<input className={css('input-edit')} value={valuePhoneNumber} onChange={handleValuePhoneNumber}/>:res.phoneNumber}</td>
                                            {res.id===idEdit?( 
                                                <td className={css('action')}>
                                                    <FontAwesomeIcon onClick={() => handleEdit()} className={css('icon','btn-remove')} icon={faCheck} style={{color: 'green'}}/>
                                                    <FontAwesomeIcon onClick={() => closeEdit()} className={css('icon','btn-remove')} icon={faBan} style={{color: 'red'}}/>
                                                </td>
                                            ):(
                                                <td className={css('action')}>
                                                    <FontAwesomeIcon className={css('icon','btn-remove')} icon={faEdit} onClick={() => openEdit(res.id)}/>
                                                    <FontAwesomeIcon onClick={() => handleRemove(res.id)} className={css('icon','btn-remove')} icon={faTrash}/>
                                                </td>
                                            )}
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Pagination  currentPage={pageIndex} pageSize={pageSize} totalItems={totalItems} onPageChange={handlePageChange}/>
                </div>
            </>
        )}
        <Register isOpen={isOpenRegister} isClose={handleCloseRegister}/>
    </>)
}

export default User;