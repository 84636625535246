import { appConfig } from '~/Config/AppConfig';
import restful from '~/Config/RestConfig';
import axios from 'axios';

const path = appConfig.API+'api/v1/quan-ly-nguoi-dung/';
class User {
    AddUser(formData){
        return axios.post(path+'nguoi-dung/',formData,{
            headers: {
              'Content-Type': 'multipart/form-data'
            },
        }).then(response=> response.data)
        .catch(error => console.log(error.response.data));
    };

    updateUser(formData){
        return axios.put(path+'nguoi-dung/',formData,{
            headers: {
              'Content-Type': 'multipart/form-data'
            },
        }).then(response=> response.data)
        .catch(error => console.log(error.response.data));
    };

    deleteUser(userId){
        return axios.delete(path+'nguoi-dung/'+userId)
        .then(response => response.data)
        .catch(error => console.log(error.response.data));
    }

    GetUser(pageIndex, pageSize){
        return axios.get(path+'nguoi-dung/'+`${pageIndex}/${pageSize}`)
        .then(response => response.data)
        .catch(error => console.log(error.response.data));
    };

    countUser(){
        return axios.get(path+'count-nguoi-dung/')
        .then(response => response.data)
        .catch(error => console.log(error.response.data));
    };

    findUser(keyword, pageIndex, pageSize){
        return axios.get(path+`find-nguoi-dung/${keyword}/${pageIndex}/${pageSize}`)
        .then(response => response.data)
        .catch(error => console.log(error.response.data));
    };

    countFindUser(keyword){
        return axios.get(path+`count-find-nguoi-dung/${keyword}`)
        .then(response => response.data)
        .catch(error => console.log(error.response.data));
    };

    getNguoiDungByUserName(UserName){
        return axios.get(path+`get-nguoi-dung-by-user-name/${UserName}`)
        .then(response => response.data)
        .catch(error => console.log(error.response.data));
    };

    getNguoiDungByUserId(UserId){
        return axios.get(path+`get-nguoi-dung-by-id/${UserId}`)
        .then(response => response.data)
        .catch(error => console.log(error.response.data));
    };
}

export default User;