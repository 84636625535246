import React, { useState, useEffect, useRef } from 'react';
import styles from './Upload.module.scss';
import className from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import File from '~/Api/File';
import Folders from '~/Api/Folders';

const css = className.bind(styles);

function Upload({openUpload, closeUpload}){
    const [dataUpload, setDataUpload] = useState([]);
    const formRef = useRef(null);

    const buildFolderTree = (data, parentId = 0) => {
        const filteredItems = data.filter((item) => item.rootId === parentId);

        if (filteredItems.length === 0) {
            return [];
        }

        const tree = filteredItems.flatMap((item) => {
            const descendants = buildFolderTree(data, item.id);
            return [item, ...descendants];
        });

        return tree;
    };

    const folderTree = buildFolderTree(dataUpload);

    //Setup Framework
    const [isOpenUpload, setIsOpenUpload] = useState(false);
    const [isDropdown, setIsDropdown] = useState(false);
    const [foldersName, setFoldersName] = useState('');
    // const handleOpen = () =>{
    //     setIsOpenUpload(!isOpenUpload);
    //     setIsDropdown(false);
    // };

    const handleDropdown = () =>{
        const folders = new Folders();
        folders.getListFolders().then((data) => {
            setDataUpload(data.data);
        });
        setIsDropdown(!isDropdown);
    };

    //Setup Data
    const [folderId, setFolderId] = useState();
    const [file, setFile] = useState();

    const defaultChange = () => {
        setFoldersName('');
        setFolderId();
        setFile();
        setIsOpenUpload(false);
        setIsDropdown(false);
    };

    const handleFolderIdChange = (item) => {
        setFolderId(item.id);
        setFoldersName(item.foldersName);
        setIsDropdown(false);
    };

    const handleFileUpload = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('FolderId', folderId);
        formData.append('dataFile', file);

        const f = new File();
        f.uploadShapeFileToGeoJSON(formData).then((response) => defaultChange());
    };

    const handleClose = () => {
        closeUpload(false);
    };

    const handleFormRef = (event) => {
        if (formRef.current && !formRef.current.contains(event.target)) {
            setIsOpenUpload(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleFormRef);
        return () => {
            document.removeEventListener('mousedown', handleFormRef);
        };
    }, []);

    return (
        <div>
            {openUpload && (
                <div>
                    <div className={css('background-form')}></div>
                    <div className={css('form-upload')} ref={formRef}>
                        <div className={css('title-upload')}>
                            <p>Upload bản đồ</p>
                        </div>
                        <div className={css('group-item-upload')}>
                            <div className={css('item-upload')}>
                                <label className={css('label')}>Thuộc mục</label>
                                <div className={css('dropdown')}>
                                    <input className={css('input-dropdown')} type='input' value={foldersName} onClick={() => setIsDropdown(true)} autoComplete="off" autoCorrect="off"/>    
                                    <FontAwesomeIcon className={css('icon')} icon={faChevronDown} onClick={handleDropdown}/>
                                </div>
                                {isDropdown&&(
                                    <div className={css('group-select')}>
                                        {folderTree.map((item, idx) => (<div key={idx} onClick={() => handleFolderIdChange(item)}>{
                                        item.level===1?"|--|--|"+item.foldersName+"|--|--|"
                                        :item.level===2?"|--|--|--|"+item.foldersName+"|--|--|--|"
                                        :"|--|"+item.foldersName+"|--|"
                                        }</div>))}
                                    </div>
                                )}
                            </div>
                            <div className={css('item-upload')}>
                                <label className={css('label')}>Upload file</label>
                                <input type='file' onChange={handleFileUpload}/>
                            </div>
                            <div className={css('group-btn')}>
                                <button className={css('btn-submit')} onClick={handleSubmit}>
                                    Xác nhận
                                </button>
                                <button className={css('btn-cancel')} onClick={handleClose}>
                                    Hủy
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Upload;
