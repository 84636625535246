import { appConfig } from '~/Config/AppConfig';
import restful from '~/Config/RestConfig';
import axios from 'axios';

const path = appConfig.API + 'api/v1/information/';
class Information{
    addInformation(formData){
        return axios.post(path+'add-information/',formData,{
            headers: {
            'Content-Type': 'multipart/form-data'
            },
        }).then(response=> response.data)
        .catch(error => console.log(error.response.data));
    };

    getInformationByFeatureId(featureId)
    {
        return axios.get(path+'get-information-by-feature-id'+'/'+featureId)
        .then(response=> response.data)
        .catch(error => console.log(error.response.data));
    };

    getFeatureIdByKeywordName(keyword)
    {
        return axios.get(path+'get-feature-id-by-keyword-name'+'/'+keyword)
        .then(response=> response.data)
        .catch(error => console.log(error.response.data));
    };
}

export default Information;