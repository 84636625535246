import { useState, useEffect } from 'react';
import className from 'classnames/bind';
import styles from './Information.module.scss';
import InformationAPI from '~/Api/Information';
import Toast from '~/Components/Controls/Toast';

const css = className.bind(styles);
function Information({ isOpen, closeInfor, featureId, data }) {
    const [ten, setTen] = useState('');
    const [diachi, setDiaChi] = useState('');
    const [giaiphap, setGiaiPhap] = useState('');
    const [chieurong, setChieuRong] = useState('');
    const [thoigian, setThoiGian] = useState('');
    const [kinhphi, setKinhPhi] = useState('');
    const [nguonvon, setNguonVon] = useState('');
    const [message, setMessage] = useState('');
    const [typeToast, setTypeToast] = useState('success');
    const [showToast, setShowToast] = useState(false);

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const handleCloseInfor = () => {
        closeInfor(false);
    };

    const handleTen = (event) => {
        setTen(event.target.value);
    };

    const handleDiaChi = (event) => {
        setDiaChi(event.target.value);
    };

    const handleGiaiPhap = (event) => {
        setGiaiPhap(event.target.value);
    };

    const handleChieuRong = (event) => {
        setChieuRong(event.target.value);
    };

    const handleThoiGian = (event) => {
        setThoiGian(event.target.value);
    };

    const handleKinhPhi = (event) => {
        setKinhPhi(event.target.value);
    };

    const handleNguonVon = (event) => {
        setNguonVon(event.target.value);
    };

    const handleSubmitInformation = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('FeatureId', featureId);
        formData.append('Name', ten);
        formData.append('Address', diachi);
        formData.append('Length', chieurong);
        formData.append('Expense', kinhphi);
        formData.append('Funds', nguonvon);
        formData.append('Solution', giaiphap);
        formData.append('Time', thoigian);

        const i = new InformationAPI();
        i.addInformation(formData).then((response) => {
            if (response.status == 200) {
                setShowToast(true);
                setMessage('Cập nhật thành công.');
                setTypeToast('success');
                data(response.data);
                setTen('');
                setDiaChi('');
                setGiaiPhap('');
                setChieuRong('');
                setThoiGian('');
                setKinhPhi('');
                setNguonVon('');
                handleCloseInfor();
            }
        });
    };

    return (
        <>
            <Toast
                message={message}
                onClose={handleCloseToast}
                showToast={showToast}
                type={typeToast}
                duration={2000}
            />
            {isOpen && (
                <div className={css('form')}>
                    <div className={css('title')}>
                        <p>Cập nhật thông tin</p>
                    </div>
                    <div className={css('group')}>
                        <div className={css('item')}>
                            <div className={css('label')}>Tên </div>
                            <div className={css('input')}>
                                <input
                                    type="input"
                                    value={ten}
                                    onChange={handleTen}
                                    autoComplete="off"
                                    autoCorrect="off"
                                ></input>
                            </div>
                        </div>
                        <div className={css('item')}>
                            <div className={css('label')}>Địa chỉ </div>
                            <div className={css('input')}>
                                <input
                                    type="input"
                                    value={diachi}
                                    onChange={handleDiaChi}
                                    autoComplete="off"
                                    autoCorrect="off"
                                ></input>
                            </div>
                        </div>
                        <div className={css('item')}>
                            <div className={css('label')}>Giải pháp </div>
                            <div className={css('input')}>
                                <textarea
                                    type="input"
                                    value={giaiphap}
                                    onChange={handleGiaiPhap}
                                    autoComplete="off"
                                    autoCorrect="off"
                                ></textarea>
                            </div>
                        </div>
                        <div className={css('item')}>
                            <div className={css('label')}>Chiều dài </div>
                            <div className={css('input')}>
                                <input
                                    type="input"
                                    value={chieurong}
                                    onChange={handleChieuRong}
                                    autoComplete="off"
                                    autoCorrect="off"
                                ></input>
                            </div>
                        </div>
                        <div className={css('item')}>
                            <div className={css('label')}>Thời gian </div>
                            <div className={css('input')}>
                                <input
                                    type="input"
                                    value={thoigian}
                                    onChange={handleThoiGian}
                                    autoComplete="off"
                                    autoCorrect="off"
                                ></input>
                            </div>
                        </div>
                        <div className={css('item')}>
                            <div className={css('label')}>Kinh phí </div>
                            <div className={css('input')}>
                                <input
                                    type="input"
                                    value={kinhphi}
                                    onChange={handleKinhPhi}
                                    autoComplete="off"
                                    autoCorrect="off"
                                ></input>
                            </div>
                        </div>
                        <div className={css('item')}>
                            <div className={css('label')}>Nguồn vốn </div>
                            <div className={css('input')}>
                                <input
                                    type="input"
                                    value={nguonvon}
                                    onChange={handleNguonVon}
                                    autoComplete="off"
                                    autoCorrect="off"
                                ></input>
                            </div>
                        </div>
                        <div className={css('group-btn')}>
                            <button className={css('btn-submit')} onClick={handleSubmitInformation}>
                                Xác nhận
                            </button>
                            <button onClick={handleCloseInfor} className={css('btn-cancel')}>
                                Hủy
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Information;
