import { MapsLayout } from '../Components/Layouts';
import Home from '../Pages/Home';
import About from '../Pages/About';

const PublicRoutes = [
    { path: '/', component: Home, layout: MapsLayout },
    { path: '/about', component: About },
];

const PrivateRoutes = [];

export { PublicRoutes, PrivateRoutes };
