import React from 'react';
import CryptoJS from 'crypto-js';
import { appConfig } from '~/Config/AppConfig';

class Crypto{
    encryptData(string){
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(string), appConfig.KEY).toString();
        return encrypted;
    };

    decryptData(string){
        const decryptedBytes = CryptoJS.AES.decrypt(string, appConfig.KEY);
        const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
        return decryptedData;
    };
}

export default Crypto;