import React, { useEffect, useRef, useState } from 'react';
import className from 'classnames/bind';
import styles from './PopupMenu.module.scss';
import Feature from '~/Api/Feature';
import Toast from '~/Components/Controls/Toast';

const css = className.bind(styles);

function PopupMenu({positionLatLng, position, rename, deleteFile, blinking, page = 'Tree', closePop, openInfor, type, openSetting, openSolution}){
    const divRef = useRef(null);
    const [isOpenUpdate, setIsOpenUpdate] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [typeToast, setTypeToast] = useState('success');
    const [showToast, setShowToast] = useState(false);
    
    const handleCloseToast = () => {
        setShowToast(false);
    };
    
    const handleOutsideClick = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const handleRename = () => {
        setIsOpen(false);
        rename({id: position.id, open: true});
    };

    const handleDeleteFile = () => {
        deleteFile({id: position.id, delete: true});
    };

    const handleSetupBlinking = () => {
        const feature = new Feature();
        feature.updateBlinking(position.id)
        .then(response => {
            if(response.status === 200){
                setMessage('Thiết lập cảnh báo thành công.');
                setTypeToast('success');
                setShowToast(true);
                setIsOpen(false);
                blinking(true);
            } else {
                setMessage(response.detail);
                setTypeToast('dangerous');
                setShowToast(true);
            }
        })
    };

    useEffect(() => {

    },[]);

    const handleUploadInformation = () =>{
        openInfor(true);
    };

    const handleButtonImageClick = () => {
        type(0);
    };

    const handleButtonVideoClick = () => {
        type(1);
    };

    const handleOpenSettingFeature = () => {
        openSetting(true);
        setIsOpen(false);
    };

    const handleOpenPriceBySolution = () => {
        openSolution(true);
        setIsOpen(false);
    };

    useEffect(() => {
        setIsOpen(false);
    },[closePop])
    
    useEffect(() => {
        setIsOpen(true);
    }, [position]);

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    return (<>
        {page === 'Tree' && (
            <div ref={divRef}>
                {isOpen && position && (
                    <div className={css('popup-menu')} style={{left: `${position.x/10}rem`, top: `${position.y/10}rem`}}>
                        <div className={css('item')} onClick={handleRename}>
                            <p>Đổi tên</p>
                        </div>
                        <div className={css('item')} onClick={handleDeleteFile}>
                            <p>Xóa</p>
                        </div>
                    </div>
                )}
            </div>
        )}
        {page === 'GoogleMap' && (
            <div ref={divRef}>
                {isOpen && position && (
                    <div className={css('popup-menu')} style={{left: `${position.x/10}rem`, top: `${position.y/10}rem`}}>
                        <div className={css('item')} onClick={handleOpenPriceBySolution}>
                            <p>Xem giải pháp</p>
                        </div>
                        <div className={css('item')} onClick={handleUploadInformation}>
                            <p>Cập nhật thông tin</p>
                        </div>
                        <div className={css('item')} onClick={handleButtonImageClick}>
                            <p>Upload ảnh</p>
                        </div>
                        <div className={css('item')} onClick={handleButtonVideoClick}>
                            <p>Upload video</p>
                        </div>
                        <div className={css('item')} onClick={handleOpenSettingFeature}>
                            <p>Thiết lập màu, biểu tượng</p>
                        </div>
                        <div className={css('item')} onClick={handleSetupBlinking}>
                            <p>Cảnh báo</p>
                        </div>
                        <div className={css('item')} onClick={handleSetupBlinking}>
                            <p>Xóa</p>
                        </div>
                    </div>
                )}
            </div>
            )}
        <Toast
            message={message}
            onClose={handleCloseToast}
            showToast={showToast}
            type={typeToast}
            duration={2000}
        />
    </>);
}

export default PopupMenu;