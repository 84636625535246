import styles from './Sildebar.module.scss';
import className from 'classnames/bind';
import Tree from '~/Components/Tree';

const css = className.bind(styles);
function Slidebar({ data, onDataChange, listIdFile }) {
    const handleSliderDataChange = (data) => {
        onDataChange(data);
    };

    const handleListIdFile = (data) => {
        listIdFile(data);
    };

    return (
        <>
            <div className={css('map-slidebar')}>
                <div className={css('slidebar')}>
                    <div className={css('box-banner')}>
                        <img className={css('banner')} src="/Assets/Images/banner_2.png" />
                    </div>
                    <Tree dataTree={data} onDataChange={handleSliderDataChange} listIdFile={handleListIdFile} />
                </div>
                <div></div>
            </div>
        </>
    );
}

export default Slidebar;
