import CommonConst from './CommonConst';

const Message = {
    E0001: {
        message: 'Lỗi kết nối mạng, hãy kiểm tra kết nối thiết bị với internet',
        type: CommonConst.MSG_TYPE_CONST.ERROR,
    },
    E0002: {
        message: 'Chưa có quyền truy cập, hãy liên hệ quản trị hệ thống',
        type: CommonConst.MSG_TYPE_CONST.ERROR,
    },

    E0003: {
        message: 'Có lỗi ngoại lệ xảy ra, hãy liên hệ quản trị hệ thống',
        type: CommonConst.MSG_TYPE_CONST.ERROR,
    },
};

export default Message;
