import React, { useEffect } from 'react';
import styles from './Dialog.module.scss';
import className from 'classnames/bind';

const css = className.bind(styles);

function Dialog({ show, onHide, title, body }) {
    useEffect(() => {
        if (show) {
            document.body.classList.add('modal-open');
            document.body.setAttribute('data-rr-ui-modal-open', true);
            document.body.style.overflow = 'hidden';
        } else {
            document.body.removeAttribute('data-rr-ui-modal-open');
            document.body.classList.remove('modal-open');
            document.body.setAttribute('style', '');
        }
    });
    return (
        <>
            {show && (
                <>
                    <div className={css('fade', 'modal-backdrop', 'show')}></div>
                    <div
                        role="dialog"
                        aria-modal="true"
                        className={css('fade', 'modal', 'show')}
                        tabindex="-1"
                        style={{ display: 'block' }}
                    >
                        <div className={css('modal-dialog')}>
                            <div className={css('modal-content')}>
                                <div className={css('modal-header')}>
                                    <div className={css('modal-title', 'h4')}>{title}</div>
                                    <button type="button" className={css('btn-close')} aria-label="Close"></button>
                                </div>
                                <div className={css('modal-body')}>{body}</div>
                                <div className={css('modal-footer')}>
                                    <button onClick={onHide} type="button" className={css('btn', 'btn-secondary')}>
                                        Close
                                    </button>
                                    <button type="button" className={css('btn', 'btn-primary')}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Dialog;
