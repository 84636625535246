import axios from 'axios';
import { appConfig } from '~/Config/AppConfig';
import restful from '~/Config/RestConfig';
import { apiConfig } from '~/Config/ApiConfig'; // em test giup anh xem chay dc gi khong


const path = appConfig.API + 'api/v1/folders/';

class Folders {
  addFolders(formData){
    return axios.post(path+'add-folders',formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
        },
    }).then(response=> response.data)
    .catch(error => console.log(error.response.data));
  };

  updateFolders(formData){
    return axios.put(path+'update-folders/',formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
        },
    }).then(response=> response.data)
    .catch(error => console.log(error.response.data));
  };

  deleteFolders(id){
    return axios.put(path+'delete-folders/'+id)
    .then(response=> response.data)
    .catch(error => console.log(error.response.data));
  };

  getListFolders() {
    return axios.get(path+'listfolders/')
    .then(response=> response.data)
    .catch(error => console.log(error.response.data));
  };

  getListFoldersByFoldersId(id) {
    return axios.get(path+`get-folders-by-folders-id/${id}`)
    .then(response => response.data)
    .catch(error => console.log(error.response.data));
  };

  getFoldersByPage(pageIndex,pageSize){
    return axios.get(path+`get-folders-by-page/${pageIndex}/${pageSize}`)
    .then(response => response.data)
    .catch(error => console.log(error.response.data));
  };

  countFolders(){
    return axios.get(path+`count-folders/`)
    .then(response => response.data)
    .catch(error => console.log(error.response.data));
  };

  findFolders(keyword, pageIndex, pageSize){
    return axios.get(path+`find-folders/${keyword}/${pageIndex}/${pageSize}`)
    .then(response => response.data)
    .catch(error => console.log(error.response.data));
  };
  
  countFindFolders(keyword){
    return axios.get(path+`count-find-folders/${keyword}`)
    .then(response => response.data)
    .catch(error => console.log(error.response.data));
  }; 
}

export default Folders;