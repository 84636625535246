import { useState, useEffect, useRef } from 'react';
import className from 'classnames/bind';
import styles from './Header.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch, faHouseFlag, faClose, faSignOut } from '@fortawesome/free-solid-svg-icons';
import Slidebar from '~/Components/Layouts/MapsLayout/Slidebar';
import GoogleMap from '~/Components/GoogleMap';
import Folders from '~/Api/Folders';
import Feature from '~/Api/Feature';
import Role from '~/Api/Role';
import InformationAPI from '~/Api/Information';
import FormLogin from '~/Components/FormLogin';
import ChangeMap from '~/Components/ChangeMap';
import proj4 from 'proj4';
import Toast from '~/Components/Controls/Toast';
import Register from '~/Components/Register';
import Crypto from '~/Function/Crypto';
import Search from '~/Api/Search';

const css = className.bind(styles);
function changePostion(value) {
    if (value[0] > 1000.0 && value[0] > 1000.0) {
        // Định nghĩa biến đổi tọa độ từ EPSG:3857 sang EPSG:4326 sử dụng UTM 48N
        proj4.defs(
            'EPSG:3857',
            '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +no_defs',
        );
        proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
        proj4.defs('EPSG:32648', '+proj=utm +zone=48 +datum=WGS84 +units=m +no_defs');
        const toCoords = proj4('EPSG:3857', 'EPSG:32648', value);
        const finalCoords = proj4('EPSG:32648', 'EPSG:4326', toCoords);
        return [finalCoords[1], finalCoords[0]];
    } else {
        return [value[1], value[0]];
    }
}

function Header() {
    const feature = new Feature();
    const loginRef = useRef(null);
    const formSearchRef = useRef(null);
    const [message, setMessage] = useState('');
    const [typeToast, setTypeToast] = useState('success');
    const [isHovered, setIsHovered] = useState(false);
    const [classBox, setClassBox] = useState('close-search');
    const [openBars, setOpenBars] = useState(false);
    const [dataUpload, setDataUpload] = useState([]);
    const [geojsonData, setGeojsonData] = useState([]);
    const [layerMap, setLayerMap] = useState('http://{s}.google.com/vt/lyrs=s,p&x={x}&y={y}&z={z}&hl=vi&gl=vn');
    const [listSearch, setListSearch] = useState([]);
    const [latlng1, setLatLng] = useState(null);
    const [listFeatureId, setListFeatureId] = useState([]);
    const [listIdFile, setListIdFile] = useState([]);
    const [isOpenRegister, setIsOpenRegister] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [userId, setUserId] = useState(0);

    const handleAuthen = () => {
        setIsHovered(!isHovered);
    };

    const handleOpenBars = () => {
        setOpenBars(true);
        const folders = new Folders();
        if (dataUpload.length <= 0) {
            folders
            .getListFolders()
            .then((data) => setDataUpload(data.data));
        }
    };

    const handleChangeMap = (data) => {
        setLayerMap(data);
    };

    const handleCloseBars = () => {
        setOpenBars(false);
        setListSearch('');
        setClassBox('close-search');
        setDataUpload([]);
    };

    const [inputValue, setInputValue] = useState('');

    const handleChange = (event) => {
        setInputValue(event.target.value);
        if(event.target.value === ''){
            setListSearch('');
            setClassBox('close-search');
        } else {
            const search = new Search;
            search.getSearchByKeyword(event.target.value).then((response) => {
                setListSearch(response.data);
                if (response.data.length > 0) {
                    setClassBox('open-search');
                }
            });
        }
    };
    
    const handleOnClickInput = () =>{
        if(inputValue === ''){
            setListSearch('');
            setClassBox('close-search');
        } else {
            const search = new Search;
            search.getSearchByKeyword(inputValue).then((response) => {
                setListSearch(response.data);
                setClassBox('open-search');
            });
        }
    };

    const [numberData, setNumberData] = useState([]);
    const handleSliderDataChange = (data) => {
        setListFeatureId(data);
        // if(data&&data.length>0&&data.length>numberData.length){
        //     const idFileAdd = data.filter(number => !numberData.includes(number));
        //     let listData = [];
        //     if (idFileAdd.length!==0) {
        //         listData.push(geojsonData);
        //         console.log(data.join('A'));
        //         feature.getFeatureByListFolder(data.join('A'))
        //         .then((d)=>{
        //             setNumberData(data);
        //             setGeojsonData(d.data);
        //         });
        //     }
        // }else if(data.length<numberData.length){   
        //     if (data.length>0) {
        //         const newArray = geojsonData.filter((item) => data.includes(item.folderId));
        //         setGeojsonData(newArray);
        //     } else if (data.length <= 0) {
        //         setGeojsonData([]);
        //     }
        //     setNumberData(data);
        // }
    };

    const handleClickItem = (point) => {
        if(point){
            const cleanedString = point.replace(/[() ]/g, '');
            const coordinatesArray = cleanedString.split(',');
            const coordinates = coordinatesArray.map((value) => parseFloat(value));
            setShowToast(true);
            setMessage('Đang thay đổi vị trí.');
            setTypeToast('success');
            setLatLng([coordinates[1],coordinates[0]]);
        }
    };

    const handleGetListIdFile = (data) => {
        setListIdFile(data);
    };

    const handleRefresh = (event) => {
        setGeojsonData([]);
        setNumberData([]);
        const listData = [];
        if(listFeatureId){
            listData.push(geojsonData);
            listFeatureId.map((res) => {
                feature.getFeatureByFolderId(res)
                .then((d)=>{
                    setNumberData(listFeatureId);
                    setGeojsonData(d.data);
                });
            });
            setGeojsonData(listData);
        }
    };

    const handleLoginRef = (event) => {
        if (loginRef.current && !loginRef.current.contains(event.target)) {
            setIsHovered(false);
        }

        if(formSearchRef.current && !formSearchRef.current.contains(event.target)){
            setListSearch([]);
            setClassBox('close-search');
        }
    };

    const [showToast, setShowToast] = useState(false);

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const handleEnterInput = (event) => {
        if (event.key === 'Enter') {
            if (listSearch.length > 0) {
                const cleanedString = listSearch[0].point.replace(/[() ]/g, '');
                const coordinatesArray = cleanedString.split(',');
                const coordinates = coordinatesArray.map((value) => parseFloat(value));
                setShowToast(true);
                setMessage('Đang thay đổi vị trí.');
                setTypeToast('success');
                setLatLng([coordinates[1],coordinates[0]]);
            }
        }
    };

    const handleCloseRegister = (data) => {
        if(data){
            setIsHovered(false);
        }
        setIsOpenRegister(data);
    };

    const handleOpenLogin = (data) => {
        setIsLogin(data);
        setIsHovered(false);
    };

    const handleLogOut = () => {
        setIsLogin(!isLogin);
        localStorage.setItem('_ConcurrencyStamp','');
        localStorage.setItem('_userName','');
        localStorage.setItem('_token','');
        localStorage.setItem('_Id','');
    };

    const handleGetUserId = (data) => {
        setUserId(data);
    };

    useEffect(() => {
        const role = new Role();
        const crypto = new Crypto();
        role.getConcurrencyStampAdmin()
        .then((response) => {
            if(response.status === 200){
                let concurrencyStamp = crypto.encryptData(response.data.concurrencyStamp);
                localStorage.setItem("_admin",concurrencyStamp);  
            }
        });
        role.getConcurrencyStampOperator()
        .then((response) => {
            if(response.status === 200){
                let concurrencyStamp = crypto.encryptData(response.data.concurrencyStamp);
                localStorage.setItem("_operator",concurrencyStamp);
            };
        });

        document.addEventListener('mousedown', handleLoginRef);
        return () => {
            document.removeEventListener('mousedown', handleLoginRef);
        };
    }, []);

    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
            document.addEventListener('contextmenu', handleContextmenu)
            return function cleanup() {
                document.removeEventListener('contextmenu', handleContextmenu)
            }
    }, []);

    return (
        <>
        {!isLogin && (
            <FormLogin isOpenRegister={handleCloseRegister} isLogin={handleOpenLogin} userId={handleGetUserId}/>
        )}
        {isLogin && (
            <>
                 <div>
                <div className={css('search')} ref={formSearchRef}>
                    <div className='bootstrap'>
                        <div className={css('input-search', classBox)}>
                            <div style={{ display: 'flex', width: "auto", paddingRight: "8%" }}>
                                <div className={css('btn-nav')} onClick={handleOpenBars}>
                                    <FontAwesomeIcon className={css('icon-bars')} icon={faBars} />
                                </div>
                                <input
                                    type="input"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    onClick={handleOnClickInput}
                                    style={{ fontSize: '1.5rem', fontFamily: 'Roboto,Arial,sans-serif' }}
                                    className={css('form-search')}
                                    value={inputValue}
                                    onChange={handleChange}
                                    onKeyPress={(e) => handleEnterInput(e)}
                                    placeholder="Tìm kiếm trạm"
                                />
                                <div className={css('btn-search')}>
                                    <FontAwesomeIcon className={css('icon-search')} icon={faSearch} />
                                </div>
                            </div>
                        </div>
                        {listSearch.length > 0 && (
                            <div class={css('box-item-search')}>
                                {listSearch.map((res) => (
                                    <div className={css('box-item')} onClick={() => handleClickItem(res.point)}>
                                        <div style={{ display: 'flex' }}>
                                            <FontAwesomeIcon className={css('icon-flag')} icon={faHouseFlag} />
                                            <p className={css('box-item-title')}>{res.name}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                {/* <div className={css('map-header')} ref={loginRef}>
                    <div className="bootstrap">
                        <div className={css('head_authen')}>
                            {isLogin && (
                                <button className={css('btn-logout')} onClick={handleLogOut}><FontAwesomeIcon icon={faSignOut}/> Đăng xuất</button>
                            )}
                            {isLogin === false && (
                                <img className={css('authenIcon')} src='/Assets/Images/login.png' onClick={handleAuthen}/>      
                            )}
                            {isHovered && (<FormLogin isOpenRegister={handleCloseRegister} isLogin={handleOpenLogin} userId={handleGetUserId}/>)}
                        </div>
                    </div>
                </div> */}
                <div className={css(!openBars ? 'hidden' : '')}>
                    <Slidebar
                        data={dataUpload}
                        onDataChange={handleSliderDataChange}
                        listIdFile={handleGetListIdFile}
                        openBars={openBars}
                    />
                    <FontAwesomeIcon onClick={handleCloseBars} className={css('icon-close')} icon={faClose} />
                </div>
            </div>
            <Toast
                message={message}
                onClose={handleCloseToast}
                showToast={showToast}
                type={typeToast}
                duration={2000}
            />
            <GoogleMap layer={layerMap} onClick={handleCloseBars} latlng={latlng1} refresh={handleRefresh} listIdFile={listIdFile} isLogin={isLogin} userId={userId} listFeatureId={listFeatureId}/>
            {/* <GoogleMap geojsonData={geojsonData} layer={layerMap} onClick={handleCloseBars} latlng={latlng1} refresh={handleRefresh} listIdFile={listIdFile} isLogin={isLogin} userId={userId}/> */}
            <ChangeMap onDataChange={handleChangeMap}/>
            {/* <Register isOpen={isOpenRegister} isClose={handleCloseRegister}/> */}
            </>
        )}   
        </>
    );
}

export default Header;