import React, {useState} from 'react';
import styles from './ChangeMap.module.scss';
import className from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';

const css = className.bind(styles);

function ChangeMap({onDataChange}){
    const [isHovered, setIsHovered] = useState(false);
    const [logo, setLogo] = useState(1);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleChangeMap = (value) => {
        if(value===1) {
            onDataChange('http://{s}.google.com/vt/lyrs=s,p&x={x}&y={y}&z={z}&hl=vi&gl=vn');
            setLogo(value);
        } else if (value === 2) {
            onDataChange('http://{s}.google.com/vt/lyrs=m,t&x={x}&y={y}&z={z}&hl=vi&gl=vn');
            setLogo(value);
        } else if (value === 3) {
            onDataChange('https://a.tile.openstreetmap.org/{z}/{x}/{y}.png');
            setLogo(value);
        }
    };

    return (
        <div 
            >
            <div className={css('btn-change-map')}
                onMouseEnter={handleMouseEnter}        
            >
                <p className={css('title')}><FontAwesomeIcon icon={faLayerGroup}/></p>
                <p className={css('text')}>Lớp bản đồ</p>
                <div className={css('black-overlay')}>
                    <img className={css('logo')} src={logo===2?'/Assets/Images/layer_map.png':logo===3?'/Assets/Images/layer_openstreetmap.png':'/Assets/Images/layer_earth.png'}/>
                </div>
            </div>
            {isHovered && 
                <div className={css('group-change-map')}
                onMouseLeave={handleMouseLeave}
                >
                    <div className={css('group-item')} onClick={() => handleChangeMap(1)}>
                        <img className={css('logo-item')} src="/Assets/Images/layer_earth.png"/>
                        <p className={css('title-item')}>Google Earth</p>
                    </div>
                    <div className={css('group-item')} onClick={() => handleChangeMap(2)}>
                        <img className={css('logo-item')} src="/Assets/Images/layer_map.png"/>
                        <p className={css('title-item')}>Google Map</p>
                    </div>
                    <div className={css('group-item')} onClick={() => handleChangeMap(3)}>
                        <img className={css('logo-item')} src="/Assets/Images/layer_openstreetmap.png"/>
                        <p className={css('title-item')}>OpenStreetMap</p>
                    </div>
                </div> 
            }
            
        </div>
    );
}

export default ChangeMap;