import { BrowserRouter as BsRouter, Routes, Route } from 'react-router-dom';
import { Fragment, useEffect } from 'react';
import { PublicRoutes } from '~/Config/RouterConfig';
import { DefaultLayout } from '~/Components/Layouts';
import { appConfig } from '~/Config/AppConfig';
import Notification from '~/Components/Notification/Notification';
import Loading from '~/Components/Loading/Loading';

const App = () => {
    useEffect(() => {
        document.title = appConfig.APP_NAME;
    }, []);

    return (
        <BsRouter>
            <div>
                <Routes>
                    {PublicRoutes.map((route, index) => {
                        let Layout = DefaultLayout;
                        if (route.layout) {
                            Layout = route.layout;
                        } else if (route === null) {
                            Layout = Fragment;
                        }
                        const Page = route.component;
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <Layout>
                                        <Loading />
                                        <Notification />
                                        <Page />
                                    </Layout>
                                }
                            />
                        );
                    })}
                </Routes>
            </div>
        </BsRouter>
    );
};

export default App;
