import { appConfig } from '~/Config/AppConfig';
import restful from '~/Config/RestConfig';
import axios from 'axios';

const path = appConfig.API;
class Authen {
    login(formData){
        return axios.post(path+'api/Authentication/login',formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(response => response.data)
        .catch(error => console.log(error.response.data));
    };


    ///Cach dung , vi du nhu vay, nha
    // Không nen dong axios nhu hien tai, moi cho moi chinh configue
    // Da vng anh
    // em apply mot cai nao dang chay giup anh xem thu
    // login2(){
    //     restful.get(apiConfig.LOGIN, {params}).then(res => {

    //     })
    // }


}

export default Authen;