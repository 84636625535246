import React from 'react';
import className from 'classnames/bind';
import styles from './Pagination.module.scss';

const css = className.bind(styles);

const Pagination = ({ currentPage, pageSize, totalItems, onPageChange }) => {
    const handleClick = (page) => {
      onPageChange(page);
    };
                    
    let totalPages = Math.ceil(totalItems/pageSize);
    if(totalPages===0)totalPages=1;
    const renderPageNumbers = () => {
      let pageNumbers = [];
      if (totalPages <= 5) {
        for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(
            <button key={i} onClick={() => handleClick(i)} className={css('btn',currentPage === i ? 'active' : '')}>
              {i}
            </button>
          );
        }
      } else {
        let startPage, endPage;
        if (currentPage <= 3) {
          startPage = 1;
          endPage = 5;
        } else if (currentPage + 2 >= totalPages) {
          startPage = totalPages - 4;
          endPage = totalPages;
        } else {
          startPage = currentPage - 2;
          endPage = currentPage + 2;
        }
  
        for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(
            <button key={i} onClick={() => handleClick(i)} className={css('btn',currentPage === i ? 'active' : '')}>
              {i}
            </button>
          );
        }
      }
  
      return pageNumbers;
    };
  
    return (
      <div className={css('form')}>
        <button className={css('btn')} onClick={() => handleClick(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        {renderPageNumbers()}
        <button className={css('btn')} onClick={() => handleClick(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    );
  };
  
  export default Pagination;