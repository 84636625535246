import React, { useEffect, useState } from 'react';
import className from 'classnames/bind';
import styles from './Register.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove, faAdd, faTrash, faEdit, faCheck, faBan, faHammer } from '@fortawesome/free-solid-svg-icons';
import User from '~/Api/User';
import Toast from '~/Components/Controls/Toast';

const css = className.bind(styles);

const Register = ({isOpen, isClose}) => {
    const [name, setName] = useState('');
    const [numberPhone, setNumberPhone] = useState('');
    const [email, setEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [message, setMessage] = useState('');
    const [typeToast, setTypeToast] = useState('success');
    const [showToast, setShowToast] = useState(false);

    const handleIsClose = () => {
        isClose(false);
    };

    const handleGetValueName = (event) => {
        setName(event.target.value);
    };

    const handleGetValueNumberPhone = (event) => {
        setNumberPhone(event.target.value);
    };

    const handleGetValueEmail = (event) => {
        setEmail(event.target.value);
    };

    const handleGetValueUserName = (event) => {
        setUserName(event.target.value);
    };

    const handleGetValuePassword = (event) => {
        setPassword(event.target.value);
    };

    const handleGetValueRePassword = (event) => {
        setRePassword(event.target.value);
    };

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const submitRegisterUser = () => {
        if(password === rePassword){
            const formData = new FormData();
            formData.append('Ten',name);
            formData.append('UserName',userName);
            formData.append('Password',password);
            formData.append('Email',email);
            formData.append('PhoneNumber',numberPhone)

            const useApi = new User();
            useApi.AddUser(formData)
            .then((respon) => {
                if(respon.status === 200){
                    setShowToast(true);
                    setMessage('Đăng ký thành công');
                    setTypeToast('success');
                    setName('');
                    setUserName('');
                    setPassword('');
                    setEmail('');
                    setNumberPhone('');
                    setRePassword('');
                    isClose(false);
                } else {
                    setShowToast(true);
                    setMessage(respon.detail);
                    setTypeToast('dangerous');
                }
            })
        } else {
            setShowToast(true);
            setMessage('Mật khẩu nhập lại không trùng khớp.');
            setTypeToast('dangerous');
        }
    }

    return(
        <>
        <Toast
            message={message}
            onClose={handleCloseToast}
            showToast={showToast}
            type={typeToast}
            duration={2000}
        />
        {isOpen && (
            <>
                <div className={css('background-form')}></div>
                <div className={css('form-register')}>
                    <div className={css('background-title')}>
                        <p className={css('title')}>Đăng ký</p>
                        <FontAwesomeIcon className={css('remove')} icon={faRemove} onClick={handleIsClose}/>
                    </div>
                    <div className={css('form-item-register')}>
                        <div className={css('item-register')}>
                            <label className={css('label-register')}>Họ tên</label>
                            <input className={css('input-register')} onChange={handleGetValueName} value={name}/>
                        </div>
                        <div className={css('item-register')}>
                            <label className={css('label-register')}>Số điện thoại</label>
                            <input className={css('input-register')} onChange={handleGetValueNumberPhone} value={numberPhone}/>
                        </div>
                        <div className={css('item-register')}>
                            <label className={css('label-register')}>Email</label>
                            <input className={css('input-register')} onChange={handleGetValueEmail} value={email}/>
                        </div>
                        <div className={css('item-register')}>
                            <label className={css('label-register')}>Tên tài khoản</label>
                            <input className={css('input-register')} onChange={handleGetValueUserName} value={userName}/>
                        </div>
                        <div className={css('item-register')}>
                            <label className={css('label-register')}>Mật khẩu</label>
                            <input className={css('input-register')} onChange={handleGetValuePassword} value={password} type='password'/>
                        </div>
                        <div className={css('item-register')}>
                            <label className={css('label-register')}>Nhập lại mật khẩu</label>
                            <input className={css('input-register')} onChange={handleGetValueRePassword} value={rePassword} type='password'/>
                        </div>
                    </div>
                    <div className={css('group-btn-register')}>
                        <button className={css('button','submit')} onClick={submitRegisterUser}>Lưu</button>
                        <button className={css('button','cancel')} onClick={handleIsClose}>Hủy</button>
                    </div>
                </div>
            </>
        )}
        </>
    );
};

export default Register;