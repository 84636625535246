import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline, Polygon, useMap, useMapEvents, GeoJSON, LayersControl  } from 'react-leaflet';
import styles from './GoogleMap.module.scss';
import { appConfig } from '~/Config/AppConfig';
import L, { setOptions } from 'leaflet';
import proj4 from 'proj4';
import TabsMap from '../TabsMap';
import InformationAPI from '../../Api/Information';
import MediaAPI from '../../Api/Media';
import Information from '../Information';
import Media from '../Media';
import Folder from '../Folder';
import SettingFeature from '../SettingFeature';
import SettingNote from '../SettingNote';
import ListFile from '../ListFile';
import PopupMenu from '../Controls/PopupMenu';
import Solution from '../Solution';
import FormSolution from '../FormSolution';
import CrossSection from '../CrossSection';
import className from 'classnames/bind';
import User from '../User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faSliders, faFile, faHammer, faChartLine, faUsers } from '@fortawesome/free-solid-svg-icons';
import ResultStorage from '~/Function/ResultStorage';
import UserApi from '~/Api/User';
import FeatureApi from '~/Api/Feature';
import FileAPI from '~/Api/File';
import ReactDOMServer from 'react-dom/server';
import MarkerClusterGroup from 'react-leaflet-cluster';

const css = className.bind(styles);

function changePostion(value) {
    if (value[0] > 1000.0 && value[0] > 1000.0) {
        // Định nghĩa biến đổi tọa độ từ EPSG:3857 sang EPSG:4326 sử dụng UTM 48N
        proj4.defs(
            'EPSG:3857',
            '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +no_defs',
        );
        proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
        proj4.defs('EPSG:32648', '+proj=utm +zone=48 +datum=WGS84 +units=m +no_defs');
        const toCoords = proj4('EPSG:3857', 'EPSG:32648', value);
        const finalCoords = proj4('EPSG:32648', 'EPSG:4326', toCoords);
        return [finalCoords[1], finalCoords[0]];
    } else {
        return [value[1], value[0]];
    }
}

function GoogleMap({ layer, latlng, refresh, listIdFile, isLogin, userId, listFeatureId }) {
    const [openGroupButton, setOpenGroupButton] = useState(false);
    const [isBlinking, setIsBlinking] = useState(true);
    const [position, setPosition] = useState([]);
    const [information, setInformation] = useState(null);
    const [image, setImage] = useState([]);
    const [video, setVideo] = useState([]);
    const [imageFeature, setImageFeature] = useState([]);
    const [videoFeature, setVideoFeature] = useState([]);
    const [openSettingFeature, setOpenSettingFeature] = useState(false);
    const [openInformation, setOpenInformation] = useState(false);
    const [idFeature, setFeatureId] = useState(0);
    const [openMedia, setOpenMedia] = useState(false);
    const [shouldFlyTo, setShouldFlyTo] = useState(false);
    const [isOpenSolution, setIsOpenSolution] = useState(false);
    const [type, setType] = useState(0);
    const [positionPopupMenu, setPositionPopupMenu] = useState(null);
    const [closePopupMenu, setClosePopupMenu] = useState(null);
    const [scale, setScale] = useState('50 km');
    const [feet, setFeet] = useState('30 mi');
    const mapRef = useRef(null);
    const [openFolder, setOpenFolder] = useState(false);
    const [openUser, setOpenUser] = useState(false);
    const [openCross, setOpenCross] = useState(false);
    const [openSolution, setOpenSolution] = useState(false);
    const [openListFile, setOpenListFile] = useState(false)
    const [openNote, setOpenNote] = useState(false);
    const [geojsonData, setGeojsonData] = useState([]);
    const [listGeo, setListGeo] = useState(null);

    const handleOpenInformation = (event) => {
        setOpenInformation(event);
    };

    const handleCloseInformation = (event) => {
        setOpenInformation(event);
    };

    const handleGetType = (event) => {
        setOpenMedia(true);
        setType(event);
    };

    const handleChangeTap = (res) => {
        setType(res);
    };

    const handleMarkerClick = (point, featureId) => {
        const newArray = [];
        newArray.push(point[0]);
        newArray.push(point[1]);
        setPosition(newArray);
        setImage([]);
        setVideo([]);
        setImageFeature([]);
        setVideoFeature([]);
        const i = new InformationAPI();
        const m = new MediaAPI();
        if (featureId) {
            setFeatureId(featureId);
            i.getInformationByFeatureId(`${featureId}`).then((d) => setInformation(d.data));
            m.getMediaByFeatureId(`${featureId}`).then((d) => {
                d.data.map((media) => {
                    if (media.type === 'image') {
                        setImage((x) => [...x, media.path]);
                        setImageFeature((x) => [...x, media]);
                    } else if (media.type === 'video') {
                        setVideo((x) => [...x, media.path]);
                        setVideoFeature((x) => [...x, media]);
                    }
                });
            });
        }
    };

    const getReponse = (event) => {
        setInformation(event);
    };

    const handlePopupClose = () => {
        setPosition([]);
        setInformation(null);
        setImage([]);
        setVideo([]);
        setImageFeature([]);
        setVideoFeature([]);
        setOpenInformation(false);
        setFeatureId(0);
        setOpenMedia(false);
        setOpenSettingFeature(false);
    };

    const handleGetFeatureId = (point, featureId) => {
        handleMarkerClick(point, featureId)
        setFeatureId(featureId);
        const newArray = [];
        newArray.push(point[0]);
        newArray.push(point[1]);
        setPosition(newArray);
    };
    
    const [numberData, setNumberData] = useState([]);

    useEffect(() => {
        const file = new FileAPI();
        if(listIdFile&&listIdFile.length>0&&listIdFile.length>numberData.length){
            const idFileAdd = listIdFile.filter(number => !numberData.includes(number));
            if (idFileAdd.length!==0) {
                file.getFileGeoToBase64(listIdFile[listIdFile.length-1])
                .then(res => {
                    if(res.status === 200){
                        if(res.data){
                            setGeojsonData([...geojsonData, res.data].sort((a,b) => a.stt - b.stt));
                        }
                    }
                })
            }
            setNumberData(listIdFile);
        } else if(listIdFile.length<numberData.length) {
            const liste = listIdFile.filter(item => numberData.includes(item));
            const newArray = geojsonData.filter(item => liste.includes(item.id));
            setGeojsonData(newArray);
            setNumberData(listIdFile);
        }
    },[listIdFile]);

    const MapComponent = () => {
        const map = useMap();
        mapRef.current = map;

        useEffect(() => {
            mapRef.current.on('popupclose', handlePopupClose);
            if (latlng && shouldFlyTo) {
                map.flyTo(latlng, 14);
                setShouldFlyTo(false);
            }
            return () => {
                mapRef.current.off('popupclose', handlePopupClose);
            };
        }, [latlng, map, shouldFlyTo]);

        const mapEvents = useMapEvents({
            zoomend: () => {
                const zoom = mapRef.current.getZoom();
                const number =
                    (156543.03392 * Math.cos((mapRef.current.getCenter().lat * Math.PI) / 180)) / Math.pow(2, zoom);
                let nearest;
                let donvi = '';
                if (number.toFixed() > 10) {
                    donvi = 'km';
                    const num = (number.toFixed() / 10).toFixed();
                    if (num < 3) {
                        nearest = 1;
                    } else if (num < 5) {
                        nearest = 3;
                    } else if (num < 10) {
                        nearest = 5;
                    } else if (num < 20) {
                        nearest = 10;
                    } else if (num < 30) {
                        nearest = 20;
                    } else if (num < 50) {
                        nearest = 30;
                    } else if (num < 100) {
                        nearest = 50;
                    } else if (num < 200) {
                        nearest = 100;
                    } else if (num < 300) {
                        nearest = 200;
                    } else if (num < 500) {
                        nearest = 300;
                    } else {
                        nearest = 500;
                    }
                } else {
                    donvi = 'm';
                    const num = number.toFixed();
                    if (num < 1) {
                        nearest = 50;
                    } else if (num < 2) {
                        nearest = 100;
                    } else if (num < 3) {
                        nearest = 200;
                    } else if (num < 5) {
                        nearest = 300;
                    } else if (num < 10) {
                        nearest = 500;
                    }
                }
                setScale(`${nearest} ${donvi}`);
                let feet = number.toFixed() * 50;
                let feetString = '';
                if (feet < 10000) {
                    feetString = `${(feet * 10).toFixed()} ft`;
                } else {
                    feetString = `${(feet / 1000).toFixed()} mi`;
                }
                setFeet(feetString);
                setClosePopupMenu(mapEvents.getZoom());
            },
        });
        return null;
    };

    const handleCloseMedia = (event) => {
        setOpenMedia(false);
    };

    const handleOpenSettingFeature = (event) => {
        setOpenSettingFeature(event);
    };

    const handleRefresh = (event) => {
        refresh(event);
        handlePopupClose();
    };

    const handleBlinking = (data) => {
        refresh(data);
    };

    const handleSetupPopup = (event, id, position) => {
        const object = { id: id, x: event.containerPoint.x, y: event.containerPoint.y };
        setFeatureId(id);
        setPositionPopupMenu(object);
        setPosition(position);
    };

    const handleSolution = (event) => {
        setIsOpenSolution(event);
    };

    const handleCloseFormSolution = (event) => {
        setIsOpenSolution(event);
    };

    const handleOpenCrossSection = () => {
        setOpenCross(true);
    };

    const handleCloseCrossSection = (data) => {
        setOpenCross(data)
    };

    const handleOpenSolution = () => {
        setOpenSolution(true);  
    };

    const handleCloseSolution = (data) => {
        setOpenSolution(data);
    };
    
    const handleOpenUser = () => {
        setOpenUser(true);
    };

    const handleCloseUser = (data) => {
        setOpenUser(data)
    };

    const handleOpenListFile = () => {
        setOpenListFile(true);
    };

    const handleCloseListFile = (data) => {
        setOpenListFile(data);
    };

    const handleOpenFolder = () => {
        setOpenFolder(true);
    };

    const handleCloseFolder = (data) => {
        setOpenFolder(data);
    };

    const handleOpenNote = () => {
        setOpenNote(true);
    };

    const handleCloseNote = (data) => {
        setOpenNote(data);
    };

    useEffect(() => {
        if(isLogin){
            handleOpenGroupButton(userId);
        }
        if(!isLogin){
            setOpenGroupButton(false);
        }
    },[isLogin]);

    const handleOpenGroupButton = (userId) => {
        const user = new UserApi();
        user.getNguoiDungByUserId(userId)
        .then(response => {
            if(response.status === 200){
                if(response.data.roleName === 'Administrator' || response.data.roleName === 'Operator'){
                    setOpenGroupButton(true);
                }
            }
        });
    };

    useEffect(() => {
        if (latlng) {
            setShouldFlyTo(true);
        }
        const interval = setInterval(() => {
            setIsBlinking((x) => !x);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [latlng]);

    const onEachFeature = (feature, layer) => {
        const popupContent = ReactDOMServer.renderToString(
            <TabsMap
                feature={feature.properties}
                image={[]}
                video={[]}
                openInfor={handleOpenInformation}
                type={handleGetType}
                changeTap={handleChangeTap}
                openSetting={handleOpenSettingFeature}
            />
        );
        // Tạo một popup và gán nội dung từ thành phần React vào
        const popupOptions = {
            maxWidth: '1000rem',
            autoPan: true, // Thêm autoPan vào đây
        };
        // Tạo một popup và gán nội dung từ thành phần React vào  
        const popup = L.popup(popupOptions).setContent(popupContent);
        layer.bindPopup(popup);
        const markersBar = L.markerClusterGroup();
        markersBar.addLayer(layer); // Thêm marker vào MarkerClusterGroup
    };
    return (
        <>
            <MapContainer
                center={[9.8127255, 106.3543423]}
                // center={[10.044262, 105.746589]}
                zoom={11}
                minZoom={5}
                scrollWheelZoom={true}
                zoomControl={false}
                style={{ minHeight: '100vh', width: '100%' }}
                attributionControl={false}
                ref={mapRef}
            >
            <TileLayer
                url={layer}
                key={appConfig.GOOGLE_API_KEY}
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />
            <MarkerClusterGroup>
            {geojsonData&&geojsonData.length>0?
                geojsonData.map((x, index) => {
                    const geoJsonObject = JSON.parse(x.json);
                    const pointToLayer = (feature, latlng) => {
                        const icon = L.icon({
                          iconUrl: `${x.icon}`,
                          iconSize: [32, 32],
                          iconAnchor: [16, 16],
                        });
                    
                        return L.marker(latlng, { icon });
                      };
                    return(
                        <GeoJSON data={geoJsonObject} color={x.color} onEachFeature={(f,l) => onEachFeature(f,l)} pointToLayer={pointToLayer}/>
                    );    
            }):null}
            </MarkerClusterGroup>
            <MapComponent />
        <div className={css('scale')}>
            <div className={css('background-scale')}>
                <div className={css('feet-value')}>
                    {'Độ cao: '}{feet}
                </div>
                <div className={css('scale-value')}>
                    {'Tỷ lệ: 1:'}{scale}
                </div>
            </div>
        </div>
        </MapContainer>
        {/* <div className={css('group-button')}>
            {openGroupButton && (
                <>
                    <button className={css('btn')} onClick={handleOpenFolder}>
                        <FontAwesomeIcon icon={faFolder}/> Thư mục
                    </button>
                    <button className={css('btn')} onClick={handleOpenNote}>
                        <FontAwesomeIcon icon={faSliders}/> Thiết lập chú thích
                    </button>
                    <button className={css('btn')} onClick={handleOpenListFile}>
                        <FontAwesomeIcon icon={faFile}/> Danh sách file
                    </button>
                    <button className={css('btn')} onClick={handleOpenSolution}>
                        <FontAwesomeIcon icon={faHammer}/> Danh sách giải pháp
                    </button>
                    <button className={css('btn')} onClick={handleOpenCrossSection}>
                        <FontAwesomeIcon icon={faChartLine}/> Mặt cắt
                    </button>
                    <button className={css('btn')} onClick={handleOpenUser}>
                        <FontAwesomeIcon icon={faUsers}/> Danh sách tài khoảny
                    </button>
                </>
            )}
        </div> */}
        <Media isOpen={openMedia} mediaId={idFeature} video={videoFeature} image={imageFeature} type={type} closeMedia={handleCloseMedia}/>
        <Information isOpen={openInformation} closeInfor={handleCloseInformation} featureId={idFeature} data={getReponse}/>
        <Folder openButton={openFolder} closeFolder={handleCloseFolder}/>
        {openSettingFeature && (<SettingFeature featureId={idFeature} closeOpenSetting={handleOpenSettingFeature} refresh={handleRefresh}/>)}
        <SettingNote openNote={openNote} closeNote={handleCloseNote}/>
        <ListFile openFile={openListFile} closeFile={handleCloseListFile}/>
        <PopupMenu positionLatLng={position} position={positionPopupMenu} page="GoogleMap" blinking={handleBlinking} closePop={closePopupMenu} openInfor={handleOpenInformation} type={handleGetType} openSetting={handleOpenSettingFeature} openSolution={handleSolution}/>
        <Solution openSolution={openSolution} closeSolution={handleCloseSolution}/>
        <FormSolution isOpen={isOpenSolution} Id={idFeature} close={handleCloseFormSolution}/>
        <CrossSection openCrossSection={openCross} closeCrossSection={handleCloseCrossSection}/>
        <User openUser={openUser} closeUser={handleCloseUser}/>
    </>);

}

export default GoogleMap;