import Function from '~/Api/Function';
import { useState, useRef, useEffect } from 'react';
import { saveAs } from 'file-saver';
function PhanBoVanToc(){
    const [file, setFile] = useState(null);
    const [base64, setBase64] = useState(null);
    const handleFile = (event) => {
        event.preventDefault();
        alert(event.target.files[0]);
        setFile(event.target.files[0]);
    };

    function downloadFile(base64String, fileName) {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        saveAs(blob, fileName);
      }

    const handleXuatFile = () => {
        const formData = new FormData();
        formData.append('dataFile', file);

        const function1 = new Function();
        function1.PhanBoVanToc(formData).then((response) => {
            console.log(response);
            if (response.status == 200) {
                setBase64(response.data);
                downloadFile(response.data, 'ket_qua.xlsx');
                setFile(null);
            } else {
                setFile(null);
            }
        });
    };

    return(
        <div>
            <input type="file" onChange={handleFile}></input>
            {/* <a
                className="styled-like-a-button"
                download={filename}
                href={`data:application/pdf;base64,${b64string}`}
            >Download</a> */}
            <button onClick={handleXuatFile}>Xuất</button>
        </div>
    );
}

export default PhanBoVanToc;