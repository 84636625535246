import { useState, useRef, useEffect } from 'react';
import className from 'classnames/bind';
import styles from './Media.module.scss';
import MediaAPI from '~/Api/Media';
import Toast from '~/Components/Controls/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faCheck, faTrash, faRemove } from '@fortawesome/free-solid-svg-icons';

const css = className.bind(styles);
function Media({ isOpen, mediaId, image, video, type, closeMedia }) {
    const [nameFileImage, setNameFileImage] = useState(null);
    const [fileImage, setFileImage] = useState(null);
    const fileRef = useRef();
    const [nameFileVideo, setNameFileVideo] = useState(null);
    const [fileVideo, setFileVideo] = useState(null);
    const [resultProgress, setProgress] = useState(0);
    const [message, setMessage] = useState('');
    const [typeToast, setTypeToast] = useState('success');
    const [showToast, setShowToast] = useState(false);

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const handleFileImageUpload = (event) => {
        event.preventDefault();
        setFileImage(event.target.files[0]);
        setNameFileImage(event.target.files[0].name);
    };

    const handleFileVideoUpload = (event) => {
        event.preventDefault();
        setFileVideo(event.target.files[0]);
        setNameFileVideo(event.target.files[0].name);
    };

    const handleButtonClick = () => {
        fileRef.current.click();
    };

    const handleSubmitImage = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('FeatureId', mediaId);
        formData.append('Type', 'image');
        formData.append('dataFile', fileImage);

        const media = new MediaAPI();
        media
            .uploadMedia({
                formData,
                progress: (percentComplete) => {
                    setProgress(percentComplete);
                },
            })
            .then((response) => {
                if (response.status == 200) {
                    setShowToast(true);
                    setMessage('Upload file thành công');
                    setTypeToast('success');
                    setFileImage(null);
                    setNameFileImage(null);
                } else {
                    setShowToast(true);
                    setMessage(response.title);
                    setTypeToast('dangerous');
                }
            });
    };

    const handleCloseMedia = () => {
        closeMedia(true);
    };

    const handleSubmitVideo = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('FeatureId', mediaId);
        formData.append('Type', 'video');
        formData.append('dataFile', fileVideo);

        const media = new MediaAPI();
        media
            .uploadMedia({
                formData,
                progress: (percentComplete) => {
                    setProgress(percentComplete);
                },
            })
            .then((response) => {
                if (response.status == 200) {
                    setShowToast(true);
                    setMessage('Upload file thành công');
                    setTypeToast('success');
                    setFileImage(null);
                    setNameFileImage(null);
                } else {
                    setShowToast(true);
                    setMessage(response.title);
                    setTypeToast('dangerous');
                }
            });
    };

    return (
        <>
            <Toast
                message={message}
                onClose={handleCloseToast}
                showToast={showToast}
                type={typeToast}
                duration={2000}
            />
            {isOpen && (
                <div className={css('content')}>
                    <div className={css('form')}>
                        <div onClick={handleCloseMedia} className={css('btn-close')}>
                            <FontAwesomeIcon icon={faRemove} />
                        </div>
                        <div className={css('group-title')}>
                            <p className={css('title')}>DANH SÁCH {type === 0 ? 'HÌNH ẢNH' : 'VIDEO'}</p>
                            <p className={css('name-file')}>
                                {type === 0 ? (nameFileImage ? nameFileImage : '') : nameFileVideo ? nameFileVideo : ''}
                            </p>
                            {resultProgress > 0 && <p>{resultProgress}</p>}
                            {type === 0 &&
                                (nameFileImage ? (
                                    <button type="button" className={css('btn-submit')} onClick={handleSubmitImage}>
                                        <FontAwesomeIcon icon={faCheck} /> Xác nhận
                                    </button>
                                ) : (
                                    <button onClick={handleButtonClick} className={css('btn')}>
                                        <FontAwesomeIcon icon={faUpload} /> Upload {type === 0 ? 'hình ảnh' : 'video'}
                                    </button>
                                ))}
                            {type === 1 &&
                                (nameFileVideo ? (
                                    <button type="button" onClick={handleSubmitVideo} className={css('btn-submit')}>
                                        <FontAwesomeIcon icon={faCheck} /> Xác nhận
                                    </button>
                                ) : (
                                    <button type="button" onClick={handleButtonClick} className={css('btn')}>
                                        <FontAwesomeIcon icon={faUpload} /> Upload {type === 0 ? 'hình ảnh' : 'video'}
                                    </button>
                                ))}
                            <input
                                ref={fileRef}
                                type="file"
                                accept={type === 0 ? '.PNG,.JPG' : '.MP4'}
                                style={{ display: 'none' }}
                                onChange={type === 0 ? handleFileImageUpload : handleFileVideoUpload}
                            />
                        </div>
                        <div className={css('table-container')}>
                            <table>
                                <thead>
                                    <tr>
                                        <th className={css('th-stt')}>STT</th>
                                        <th className={css('th-ten')}>Tên file</th>
                                        <th className={css('th-ha')}>Hình ảnh</th>
                                        <th className={css('th-th')}>Thao tác</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {type === 0
                                        ? image.length > 0 &&
                                          image.map((x, idx) => (
                                              <tr>
                                                  <td>{idx + 1}</td>
                                                  <td>{x.name}</td>
                                                  <td>
                                                      <img
                                                          style={{ width: '4rem', height: '4rem', padding: '0.3rem' }}
                                                          src={x.path}
                                                      />
                                                  </td>
                                                  <td>
                                                      <FontAwesomeIcon className={css('btn-remove')} icon={faTrash} />
                                                  </td>
                                              </tr>
                                          ))
                                        : video.length > 0 &&
                                          video.map((x, idx) => (
                                              <tr>
                                                  <td>{idx + 1}</td>
                                                  <td>{x.name}</td>
                                                  <td>
                                                      <video
                                                          style={{ width: '4rem', height: '4rem', padding: '0.3rem' }}
                                                          src={x.path}
                                                      ></video>
                                                  </td>
                                                  <td>
                                                      <FontAwesomeIcon className={css('btn-remove')} icon={faTrash} />
                                                  </td>
                                              </tr>
                                          ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Media;
