import styles from './SettingFeature.module.scss';
import className from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench, faRemove } from '@fortawesome/free-solid-svg-icons';
import { useState, useRef, useEffect } from 'react';
import NoteAPI from '~/Api/Note';
import FeatureAPI from '~/Api/Feature';

const css = className.bind(styles);

function SettingFeature({ featureId, closeOpenSetting, refresh }) {
    const [color, setColor] = useState('');
    const [openLibrary, setOpenLibrary] = useState(false);
    const [listNote, setListNote] = useState([]);
    const colorRef = useRef();
    const [pathIcon, setPathIcon] = useState('');

    const handleChange = (event) => {
        const newColor = event.target.value;
        setColor(newColor);
    };

    const handleOnClickInput = () => {
        colorRef.current.click();
    };

    const handleOpenForm = () => {
        if (openLibrary) {
            setOpenLibrary(false);
        } else {
            setOpenLibrary(true);
            const n = new NoteAPI();
            n.getListNote().then((response) => {
                setListNote(response.data);
            });
        }
    };

    const handleCloseOpenSetting = (event) => {
        closeOpenSetting(false);
    };

    const handleChangeIcon = (value) => {
        setPathIcon(value);
    };

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append('Id', featureId);
        formData.append('Color', color);
        formData.append('Icon', pathIcon);

        const f = new FeatureAPI();
        f.updateFeatureIconAndColor({formData})
        .then(response => {
            refresh(true);
        }).catch(error => {
        });
    };

    useEffect(() => {
        const f = new FeatureAPI();
        f.getFeatureById(featureId).then((res) => {
            setPathIcon(res.data.icon);
            setColor(res.data.color);
        });
    }, []);

    return (
        <>
            <div className={css('background-form')}></div>
            <div className={css('form-setting')}>
                <div className={css('background-title')}>
                    <p className={css('title')}>Thiết lập biểu tượng, màu sắc</p>
                    <FontAwesomeIcon onClick={handleCloseOpenSetting} className={css('remove')} icon={faRemove} />
                </div>
                <div className={css('form-update')}>
                    <div className={css('item')}>
                        <div className={css('item-title')}>Biểu tượng:</div>
                        <img className={css('icon')} src={pathIcon} />
                        <button onClick={handleOpenForm} className={css('btn')}>
                            Thư viện
                        </button>
                    </div>
                    <div className={css('item')}>
                        <div className={css('item-title')}>Màu:</div>
                        <div
                            onClick={handleOnClickInput}
                            className={css('item-color')}
                            style={{ backgroundColor: color }}
                        ></div>
                        <button className={css('btn')} onClick={handleOnClickInput}>
                            Đổi màu
                        </button>
                        <input
                            className={css('input-color')}
                            ref={colorRef}
                            type="color"
                            value={color}
                            onChange={handleChange}
                        />
                    </div>
                    {openLibrary && (
                        <div className={css('item')}>
                            <div className={css('form-library')}>
                                {listNote.length > 0 &&
                                    listNote.map((res) => (
                                        <div className={css('item-note')} onClick={() => handleChangeIcon(res.path)}>
                                            <img className={css('item-img')} src={res.path} />
                                            <p className={css('item-title-note')}>{res.name}</p>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                    <div className={css('btn-group')}>
                        <button onClick={handleSubmit} className={css('btn-submit')}>
                            Xác nhận
                        </button>
                        <button onClick={handleCloseOpenSetting} className={css('btn-cancel')}>
                            Hủy
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SettingFeature;
