import Header from './Header';
import Note from '../../Note';
import PhanBoVanToc from '~/Components/PhanBoVanToc';

const MapsLayout = ({ children }) => {
    return (
        <div>
            <Header />
            {/* <Note /> */}
            {/* <PhanBoVanToc/> */}
            <div className="container">
                {/* <Slidebar /> */}
                <div className="content">{children}</div>
            </div>
        </div>
    );
};

export default MapsLayout;
