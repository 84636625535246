import React, { useEffect, useState } from 'react';
import className from 'classnames/bind';
import styles from './ListFile.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faRemove, faTrash, faUpload, faEdit, faCheck, faBan, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import File from '~/Api/File';
import Folders from '~/Api/Folders';
import Toast from '~/Components/Controls/Toast';
import Upload from '~/Components/Upload';
import Pagination from '~/Components/Controls/Pagination';

const css = className.bind(styles);

const ListFile = ({openFile, closeFile}) => {
    const [IdEdit, setIdEdit] = useState(0);
    const [openListFile, setOpenListFile] = useState(false);
    const [lFile, setLFile] = useState([]);
    const [nameFile, setNameFile] = useState('');
    const [folder, setFolder] = useState('');
    const [folderId, setFolderId] = useState(0);
    const [listFolder, setListFolder] = useState([]);
    const [isOpenListFolder, setIsOpenListFolder] = useState(false);
    const [message, setMessage] = useState('');
    const [typeToast, setTypeToast] = useState('success');
    const [showToast, setShowToast] = useState(false);
    const [openUploadFile, setOpenUploadFile] = useState(false);
    const pageSize = 10;
    const [pageIndex, setPageIndex] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const [valueSearch, setValueSearch] = useState('');

    useEffect(() => {
        if(openFile){
            handleOpenListFile();
        }
    },[openFile])

    const handleValueSearch = (event) => {
        setValueSearch(event.target.value);
        const file = new File();
        if(event.target.value === '' || event.target.value === null){
            file.getFilesAndFolderName(pageIndex, pageSize)
            .then(response => {
                if(response.status === 200){
                    setLFile(response.data);
                }
            });
            file.countFilesAndFolderName(pageIndex, pageSize)
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            })
        } else {
            file.findFilesAndFolderName(event.target.value, pageIndex, pageSize)
            .then(response => {
                if(response.status === 200){
                    setLFile(response.data);
                }
            });
            file.countFindFilesAndFolderName(event.target.value)
            .then(response => {
                if(response.status=== 200){
                    setTotalItems(response.data);
                }
            })
        }
    };

    const DefaultPage = () => {
        setIsOpenListFolder(false);
        setListFolder([]);
        setFolderId(0);
        setIdEdit(0);
    };

    const handleShowListFolder = () => {
        if(isOpenListFolder){
            setIsOpenListFolder(!isOpenListFolder);
        } else {
            setIsOpenListFolder(!isOpenListFolder);
            const folders = new Folders();
            folders.getListFolders()
            .then((respon) => {
                if(respon.status == 200) {
                    setListFolder(respon.data);
                }
            })
        }
    };

    const handleOpenListFile = () => {
        if (openListFile) {
            setOpenListFile(false);
            setPageIndex(1);
            closeFile(false);
        } else {
            setOpenListFile(true);
            setPageIndex(1);
            const file = new File();
            file.countFilesAndFolderName()
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            })
        }
    };

    const fetchFile = () => {
        const file = new File();
        if(valueSearch === '' || valueSearch === null){
            file.getFilesAndFolderName(pageIndex, pageSize)
            .then(response => {
                if(response.status === 200){
                    setLFile(response.data);
                }
            });
            file.countFilesAndFolderName(pageIndex, pageSize)
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            })
        } else {
            file.findFilesAndFolderName(valueSearch, pageIndex, pageSize)
            .then(response => {
                if(response.status === 200){
                    setLFile(response.data);
                }
            });
            file.countFindFilesAndFolderName(valueSearch)
            .then(response => {
                if(response.status=== 200){
                    setTotalItems(response.data);
                }
            })
        } 
    };

    useEffect(() => {
        fetchFile();
    },[pageIndex]);

    const handleOpenEdit = (IdEdit) => {
        setIsOpenListFolder(false);
        const editingRow = lFile.find((res) => res.id === IdEdit);
        setNameFile(editingRow?.nameFile || '');
        setFolder(editingRow?.foldersName || '');
        setFolderId(editingRow?.folderId || 0);
        setIdEdit(IdEdit);
    };

    const handleGetValueNameFile = (event) => {
        setNameFile(event.target.value);
    };

    const handleGetValueFolder = (event) => {
        setFolder(event.target.value);
    };

    const handleClickItemFolder = (folderId, foldersName) => {
        setFolder(foldersName);
        setFolderId(folderId);
    };

    const handlePageChange = (data) => {
        setPageIndex(data);
    };

    const handleEdit = (id) => {
        const formData = new FormData();
        formData.append('Id', id);
        formData.append('NameFile', nameFile);
        formData.append('FolderId', folderId);

        const file = new File();
        file.updateFiles(formData)
        .then((respon) => {
            if(respon.status === 200){
                file.getFilesAndFolderName()
                .then((res) => {
                    if(res.status === 200){
                        DefaultPage();
                        setMessage('Cập nhật thành công!');
                        setTypeToast('success');
                        setShowToast(true);
                        setLFile(res.data);
                    } else {
                        setMessage(res.detail);
                        setTypeToast('dangerous');
                        setShowToast(true);
                    }
                })
            } else {
                setMessage(respon.detail);
                setTypeToast('dangerous');
                setShowToast(true);
            }
        });
    };

    const handleOpenUpload = () => {
        setOpenUploadFile(true);
        handleOpenListFile();
    };

    const handleCloseUpload = (data) => {
        setOpenUploadFile(data);
        handleOpenListFile();
    };

    const closeEdit = () => {
        DefaultPage();
    };

    const handleCloseToast = () => {
        setShowToast(false);
    };

    return (<>
        <Toast
            message={message}
            onClose={handleCloseToast}
            showToast={showToast}
            type={typeToast}
            duration={2000}
        />
        {/* <button className={css('btn-list-file')} onClick={handleOpenListFile}>
            <FontAwesomeIcon icon={faFile}/> Danh sách file
        </button> */}
        {openListFile && (
            <>
                <div className={css('background-form')}>
                </div>
                <div className={css('form-list-file')}>
                    <div className={css('background-title')}>
                        <p className={css('title')}>DANH SÁCH FILE BẢN ĐỒ</p>
                        <FontAwesomeIcon className={css('remove')} icon={faRemove} onClick={handleOpenListFile}/>
                    </div>
                    <div className={css('table-list-file')}>
                        <div className={css('group-search')}>
                            <input className={css('input-search')} placeholder='Tìm kiếm file' onChange={handleValueSearch} value={valueSearch} />
                            <label className={css('label')}>Thêm thư mục</label>
                            <button className={css('btn-upload-file')} onClick={handleOpenUpload}><FontAwesomeIcon icon={faUpload}/> Upload file</button>
                        </div>
                        <div className={css('container')}>
                            <table>
                                <thead>
                                    <tr>
                                        <th className={css('th-stt')}>STT</th>
                                        <th className={css('th-name')}>Tên file</th>
                                        <th className={css('th-folder')}>Thư mục</th>
                                        <th className={css('th-action')}>Thao tác</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        lFile.map((res, idx) => (
                                            <tr>
                                                <td className={css('td-stt')}>{(pageIndex - 1)*pageSize+idx+1}</td>
                                                <td className={css('td-name')}>{res.id===IdEdit?<input className={css('input-search')} value={nameFile} onChange={handleGetValueNameFile}/>:res.nameFile}</td>
                                                <td className={css('td-folder')}>
                                                    {res.id===IdEdit?<>
                                                        <div className={css('group-edit-folder')}>
                                                            <div className={css('dropdown-folder')}>
                                                                <input className={css('input-search')} value={folder}/>
                                                                {isOpenListFolder && (
                                                                    <div className={css('popup-list-file')} style={{marginLeft: '1rem'}}>
                                                                        {listFolder && listFolder.map((res) =>
                                                                            <p onClick={() => handleClickItemFolder(res.id, res.foldersName)} style={{cursor: 'default'}}>{res.level===1?"|--|--|"+res.foldersName+"|--|--|"
                                                                            :res.level===2?"|--|--|--|"+res.foldersName+"|--|--|--|"
                                                                            :"|--|"+res.foldersName+"|--|"}</p>
                                                                        )}
                                                                    </div>  
                                                                )}      
                                                            </div>
                                                            <button style={{height: '1rem', marginTop: '1rem'}} onClick={handleShowListFolder()}><FontAwesomeIcon className={css('btn-down')} icon={faChevronDown}/></button>
                                                        </div>
                                                    </>:
                                                    res.foldersName}
                                                </td>
                                                {res.id===IdEdit ? (
                                                    <td className={css('action')}>
                                                        <FontAwesomeIcon onClick={() => handleEdit(res.id)} className={css('icon','btn-remove')} icon={faCheck} style={{color: 'green', paddingRight: '1rem'}}/>
                                                        <FontAwesomeIcon onClick={() => closeEdit()} className={css('icon','btn-remove')} icon={faBan} style={{color: 'red'}}/>
                                                    </td>
                                                ) : (
                                                    <td className={css('td-action')}>
                                                        <FontAwesomeIcon className={css('btn-remove')} icon={faEdit} style={{marginRight: '0.5rem'}} onClick={() => handleOpenEdit(res.id)}/>
                                                        <FontAwesomeIcon onClick={() => null} className={css('btn-remove')} icon={faTrash}/>
                                                    </td>
                                                )}
                                            </tr> 
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Pagination  currentPage={pageIndex} pageSize={pageSize} totalItems={totalItems} onPageChange={handlePageChange}/>
                </div>
            </>
        )}
        <Upload openUpload={openUploadFile} closeUpload={handleCloseUpload} />
    </>);
}

export default ListFile;
