import { appConfig } from '~/Config/AppConfig';
import restful from '~/Config/RestConfig';
import axios from 'axios';

const path = appConfig.API + 'api/v1/media/';
class Media {
    uploadMedia({formData, progress}){
      return axios.post(path+'upload-media',formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentComplete = (progressEvent.loaded / progressEvent.total) * 100;
          progress(percentComplete);
        },
      }).then(response=> response.data)
      .catch(error => console.log(error.response.data));
    };

    getMediaByFeatureId(featureId){
      return axios.get(path+'get-media-by-feature-id'+'/'+featureId)
      .then(response=> response.data)
      .catch(error => console.log(error.response.data));
    };

    getMediaImageByFeatureId(featureId){
      return axios.get(path+'get-list-media-image-by-feature-id/'+featureId)
      .then(response=> response.data)
      .catch(error => console.log(error.response.data));
    };

    getMediaVideoByFeatureId(featureId){
      return axios.get(path+'get-media-video-by-feature-id/'+featureId)
      .then(response=> response.data)
      .catch(error => console.log(error.response.data));
    };
}

export default Media;