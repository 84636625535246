import React from 'react';
import Crypto from '~/Function/Crypto';

class ResultStorage{
    getConcurrencyStampAdmin(){
        if(localStorage.getItem('_admin')!==null && localStorage.getItem('_admin')!==''){
            const crypto = new Crypto();
            return crypto.decryptData(localStorage.getItem('_admin')).replace(/"/g, '');
        }
    };

    getConcurrencyStampOperator(){
        if(localStorage.getItem('_operator')!==null && localStorage.getItem('_operator')!==''){
            const crypto = new Crypto();
            let string = localStorage.getItem('_operator');
            return crypto.decryptData(string).replace(/"/g, '');
        }
    };
}

export default ResultStorage;