import React, { useRef, useState, useEffect } from 'react';
import className from 'classnames/bind';
import styles from './VideoPlayer.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlay, faPause, faExpand, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';


const css = className.bind(styles);

function VideoPlayer({url}) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === url.length - 1 ? 0 : prevSlide + 1));
    setIsPlaying(false);
    videoRef.current.src = url[currentSlide];
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? url.length - 1 : prevSlide - 1));
    setIsPlaying(false);
    videoRef.current.src = url[currentSlide];
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleLoadedMetadata = () => {
      setDuration(videoElement.duration);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(videoElement.currentTime);
    };

    const handleVideoEnd = () => {
      setIsPlaying(false);
      setCurrentTime(0);
    };

    // Gán các sự kiện và xóa chúng khi component bị hủy
    videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    videoElement.addEventListener('ended', handleVideoEnd);

    return () => {
      videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      videoElement.addEventListener('ended', handleVideoEnd);
    };
  }, []);

  const handleSeek = (event) => {
    const seekTime = event.target.value;
    videoRef.current.currentTime = seekTime;
  };


  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleVolumeChange = (event) => {
    const volume = event.target.value;
    videoRef.current.volume = volume;
  };

  const handleFullScreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) {
      videoRef.current.msRequestFullscreen();
    }
  };

  return (
    <div>
      <video className={css("video")} ref={videoRef}>
        <source src={url[currentSlide]} type="video/mp4" />
        Your browser does not support the video tag.
      </video>  
      <div className={css("btn-group")}>
        <button onClick={handlePlayPause}>{isPlaying ? <FontAwesomeIcon style={{height: '2rem', width: '2rem'}} icon={faPause}/> : <FontAwesomeIcon style={{height: '2rem', width: '2rem'}} icon={faPlay}/>}</button>
        <input
          className={css("seek")}
          type="range"
          min={0}
          max={duration}
          step={0.1}
          value={currentTime}
          onChange={handleSeek}
        />
        <input
          className={css("volume")}
          type="range"
          min={0}
          max={1}
          step={0.1}
          onChange={handleVolumeChange}
        />
        <FontAwesomeIcon style={{height: '2rem', width: '2rem'}} onClick={handleFullScreen} icon={faExpand}/>
      </div>
      <FontAwesomeIcon className={css("prev-button")} onClick={prevSlide} icon={faChevronLeft}>
      </FontAwesomeIcon>
      <FontAwesomeIcon className={css("next-button")} onClick={nextSlide} icon={faChevronRight}>
      </FontAwesomeIcon>
    </div>
  );
}

export default VideoPlayer;