import { appConfig } from '~/Config/AppConfig';
import restful from '~/Config/RestConfig';
import axios from 'axios';

const path = appConfig.API + 'api/v1/note/';
class Note {
  uploadNote(formData){
    return axios.post(path+'upload-note/',formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    }).then(response=> response.data)
    .catch(error => console.log(error.response.data));
  };

  renameNote(formData){
    return axios.put(path+'rename-note/',formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    }).then(response=> response.data)
    .catch(error => console.log(error.response.data));
  };

  deleteNote(id){
    return axios.put(path+'delete-note/'+id)
    .then(response=> response.data)
    .catch(error => console.log(error.response.data));
  };

  getListNote(){
    return axios.get(path+'get-list-note/')
    .then(response=> response.data)
    .catch(error => console.log(error.response.data));
  };

  getListNoteByPage(pageIndex,pageSize){
    return axios.get(path+`get-list-note-by-page/${pageIndex}/${pageSize}`)
    .then(response => response.data)
    .catch(error => console.log(error.response.data));
  };

  countNote(){
    return axios.get(path+'count-note/')
    .then(response => response.data)
    .catch(error => console.log(error.response.data));
  };

  findNote(search, pageIndex, pageSize){
    return axios.get(path+`find-note/${search}/${pageIndex}/${pageSize}`)
    .then(response => response.data)
    .catch(error => console.log(error.response.data));
  };

  countFindNote(search){
    return axios.get(path+`count-find-note/${search}`)
    .then(response => response.data)
    .catch(error => console.log(error.response.data));
  }
}

export default Note;