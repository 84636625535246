import { useState, useEffect } from 'react';
import className from 'classnames/bind';
import styles from './TabsMap.module.scss';
import ImageSlider from '~/Components/Controls/ImageSilde';
import VideoPlayer from '~/Components/Controls/VideoPlayer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faUpload } from '@fortawesome/free-solid-svg-icons';
import ResultStorage from '~/Function/ResultStorage';

const css = className.bind(styles);
function TabsMap({ feature, image, video, openInfor, type, changeTap, openSetting }) {
    const [activeTab, setActiveTab] = useState('thongtin');
    const [isOpenUpdate, setIsOpenUpdate] = useState(false);

    useEffect(() => {
        let concurrencyStamp = localStorage.getItem('_ConcurrencyStamp');
        const result = new ResultStorage();
        if(result.getConcurrencyStampAdmin() === concurrencyStamp || result.getConcurrencyStampOperator() === concurrencyStamp){
            setIsOpenUpdate(true);
        } else {
            setIsOpenUpdate(false);
        }
    },[feature]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (tab === 'thongtin') {
            changeTap(2);
        } else if (tab === 'hinhanh') {
            changeTap(0);
        } else if (tab === 'video') {
            changeTap(1);
        }
    };
    
    const fieldNames = Object.keys(feature);
    const handleUploadInformation = () => {
        openInfor(true);
    };

    const handleButtonImageClick = () => {
        type(0);
    };

    const handleButtonVideoClick = () => {
        type(1);
    };

    return (
        <>
            <div className={css('tabs')}>
                <div className={css('tabs-header')}>
                    <div
                        className={css('tab-item', `${activeTab === 'thongtin' ? 'active' : ''}`)}
                        onClick={() => handleTabClick('thongtin')}
                    >
                        Thông tin
                    </div>
                    {/* <div
                        className={css(`tab-item`, `${activeTab === 'hinhanh' ? 'active' : ''}`)}
                        onClick={() => handleTabClick('hinhanh')}
                    >
                        Hình ảnh
                    </div>
                    <div
                        className={css(`tab-item`, `${activeTab === 'video' ? 'active' : ''}`)}
                        onClick={() => handleTabClick('video')}
                    >
                        Video
                    </div> */}
                </div>
                <div className={css("tabs-content")}>
                    {activeTab === 'thongtin' && (
                        fieldNames?(
                            <div className={css("info")}>
                                {fieldNames!==null && fieldNames!=="" && fieldNames.map(fieldName => (
                                    feature[fieldName]!==null && feature[fieldName]!=="" && (
                                        <div className={css("item-info")}>
                                            <div className={css("label")}>{fieldName}:</div> 
                                            <div className={css("txt")}>{feature[fieldName]}</div>
                                        </div>
                                    )
                                ))}
                            </div>
                        ) : (
                            <div className={css('tab-upload')}>
                                <p className={css("message")}>Chưa cập nhật thông tin.</p>
                                {isOpenUpdate && (
                                    <div className={css("btn-upload")} onClick={handleUploadInformation}>
                                        <div className={css('btn')}>
                                            <FontAwesomeIcon style={{display: 'inline-block', float: 'left', width: '20%', paddingLeft:'0.5rem'}} size='1x' icon={faEdit} />
                                            <p style={{display: 'inline-block', float: 'left', width: '80%', fontSize: '0.9rem'}}>Cập nhật thông tin</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    )}
                    {activeTab === 'hinhanh' && (
                        image.length>>0 ? (
                            <div className={css("tab-pane")}>
                                <ImageSlider urlArr={image}/>
                            </div>
                        ) : (
                            <div className={css('tab-upload')}>
                                <p className={css("message")}>Chưa cập nhật hình ảnh.</p>
                                {isOpenUpdate && (
                                    <div className={css("btn-upload")} onClick={handleButtonImageClick}>
                                        <div className={css('btn')}>
                                            <FontAwesomeIcon style={{display: 'inline-block', float: 'left', width: '20%', paddingLeft:'0.5rem'}} size='1x' icon={faUpload} />
                                            <p style={{display: 'inline-block', float: 'left', width: '80%', fontSize: '0.9rem'}}>Tải file Media</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    )}
                    {activeTab === 'video' && (
                        video.length>>0 ? (
                            <div className={css("tab-pane")}>
                                <VideoPlayer
                                    className={css("video")}
                                    url={video}
                                />
                            </div>
                        ) : (
                            <div className={css('tab-upload')}>
                                <p className={css("message")}>Chưa cập nhật video.</p>
                                {isOpenUpdate && (
                                    <div className={css("btn-upload")} onClick={handleButtonVideoClick}>
                                        <div className={css('btn')}>
                                            <FontAwesomeIcon style={{display: 'inline-block', float: 'left', width: '20%', paddingLeft:'0.5rem'}} size='1x' icon={faUpload} />
                                            <p htmlFor="fileInput" style={{display: 'inline-block', float: 'left', width: '80%', fontSize: '0.9rem'}}>Tải file Media</p>
                                        </div>
                                    </div> 
                                )}
                            </div>
                        ) 
                    )}
                </div>
            </div>
    </>);
}

export default TabsMap;
